import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Container,
  IconButton,
  LinearProgress,
  InputAdornment,
  Grid,
  Card,
  InputLabel
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { VisibilityOff, Visibility, Height } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import { ArrowBack, Subscriptions } from "@mui/icons-material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import md5 from "md5";
import axios from "axios";
import Swal from 'sweetalert2';
export default function SupervisorSignupDetails() {
  const navigate = useNavigate();
  const styles = {
    container: {
      minHeight: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
    },
    backgroundImageContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      backgroundImage: 'url("/mavesory.png")',
      backgroundSize: '45%',
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      zIndex: -3,
    },
    innerContainer: {
      backgroundColor: '#fff',
      borderRadius: '20px',
      padding: '20px',
      maxWidth: '540px',
      width: '100%',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      borderTop: '3px solid rgba(0, 0, 0, 0.1)',
      animation: 'fadeIn 0.5s ease-in-out',
    },
    formTitle: {
      color: '#2C3E2D',
      marginBottom: '20px',
      fontWeight: 'bold',
    },
    textField: {
      background: 'white',
      borderRadius: '4px',
      marginBottom: '15px',
    },
    nextButton: {
      background: '#7A9483',
      color: 'white',
      padding: '10px 30px',
      borderRadius: '20px',
      marginTop: '20px',
      '&:hover': {
        background: '#658570',
      },
    },
    card: {
      marginBottom: '10px',
      cursor: 'pointer',
      backgroundColor: '#a1c2a2',
      borderRadius: '10px',
      alignItems: 'center',
      transition: 'all 0.3s ease',
    },
    selectedCard: {
      border: '5px solid #b3946d',
    },
  };

  // CSS for animations
  const animations = `
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;

  const fields = [
    "id",
    "practiceId",
    "firstName",
    "dob",
    "lastName",
    "createdBy",
    "updatedBy",
    "phone",
    "email",
    "address",
    "city",
    "state",
    "zip",
    "type",
    "password",
    "profilePictureURL",
    "gender",
    "preferredGender",
    "ethnicity",
    "race",
    "licenseType",
    "licenseState",
    "licenseNumber",
    "initialLicensureDate",
    "licenseExpirationDate",
    "supervisortrainingtitle",
    "dateofCompletedTraining",
    "uploadTrainingCertificateURL",
    "liabilityInsuranceCarrier",
    "policyNumber",
    "insurancePolicyStartDate",
    "insurancePolicyEndDate",
    "uploadCopyofInsuranceURL",
    "pdf",
    "inActive",
    "userAggreementCheck",
    "createdDate",
    "updatedDate",
    "black",
    "white",
    "americanIndian",
    "nativeHawaiian",
    "asian",
    "middleEastern",
    "multiracial",
    "other",
    "lpcSupervisor",
    "lcswSupervisor",
    "lmftSupervisor",
    "bcba",
    "licensedSpecialist",
    "psychologist",
    "male",
    "female",
    "nonBinary",
    "intersex",
    "transWoman",
    "transMan",
    "hispanic",
    "notHispanic",
    "lpcAssociate",
    "lmftAssociate",
    "lmswormsw",
    "lcdci",
    "bcbaTrainee",
    "psychologicalAssociate",
    "pendingLicens",
    "directhoursrequired",
    "directhourshave",
    "indirecthoursrequired",
    "indirecthourshave",
    "profilePictureBase64",
    "uploadTrainingCertificateBase64",
    "uploadCopyofInsuranceBase64",
    "supscription",
    "others",
    "sigantures",
  ];
  const defValues = {
    id: 0,
    practiceId: 0,
    firstName: '',
    lastName: '',
    createdBy: '',
    updatedBy: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    type: '',
    password: '',
    profilePictureURL: '',
    gender: '',
    preferredGender: '',
    ethnicity: '',
    race: '',
    licenseType: '',
    licenseState: '',
    licenseNumber: '',
    initialLicensureDate: '',
    licenseExpirationDate: '',
    supervisortrainingtitle: '',
    dateofCompletedTraining: '',
    uploadTrainingCertificateURL: '',
    liabilityInsuranceCarrier: '',
    policyNumber: '',
    insurancePolicyStartDate: '',
    insurancePolicyEndDate: '',
    uploadCopyofInsuranceURL: '',
    pdf: '',
    inActive: false,
    userAggreementCheck: '',
    createdDate: '',
    updatedDate: '',
    black: '',
    white: '',
    americanIndian: '',
    nativeHawaiian: '',
    asian: '',
    middleEastern: '',
    multiracial: '',
    other: '',
    lpcSupervisor: '',
    lcswSupervisor: '',
    lmftSupervisor: '',
    bcba: '',
    licensedSpecialist: '',
    psychologist: '',
    male: '',
    female: '',
    nonBinary: '',
    intersex: '',
    transWoman: '',
    transMan: '',
    hispanic: '',
    notHispanic: '',
    lpcAssociate: '',
    lmftAssociate: '',
    lmswormsw: '',
    lcdci: '',
    bcbaTrainee: '',
    psychologicalAssociate: '',
    pendingLicens: '',
    directhoursrequired: '',
    directhourshave: '',
    indirecthoursrequired: '',
    indirecthourshave: '',
    profilePictureBase64: '',
    uploadTrainingCertificateBase64: '',
    uploadCopyofInsuranceBase64: "",
    supscription: '',
    others: '',
    sigantures: '',
    dob: ""
  };
  const validationSchema = Yup.object({
    supervisortrainingtitle: Yup.string().required("Please Enter the Tittle"),
    licenseNumber: Yup.string()
      .required("License number is required")
      .matches(/^[a-zA-Z0-9]+$/, "License number must be alphanumeric"),
    initialLicensureDate: Yup.date()
      .required("Initial licensure date is required")
      .typeError("Invalid date format"),
    licenseExpirationDate: Yup.date()
      .required("License expiration date is required")
      .typeError("Invalid date format")
      .min(
        Yup.ref("initialLicensureDate"),
        "Expiration date must be later than licensure date"
      ),
    liabilityInsuranceCarrier: Yup.string()
      .required("Liability insurance carrier is required"),
    policyNumber: Yup.string()
      .required("Policy number is required"),
  });


  const {
    register,
    control,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [showForm, setShowForm] = useState(0);

  const [stateLicenseBase64, setStateLicenseBase64] = useState("");
  const [stateLicenseURL, setStateLicenseURL] = useState("");

  const handleFileChangeState = (event) => {
    const file = event.target.files[0];
    if (file) {
      setStateLicenseURL(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setStateLicenseBase64(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [AgreementError, setAgreementError] = useState(false);
  const [Agreement, setAgreement] = useState(null);

  const [base64ImageAgreement, setBase64ImageAgreement] = useState("");
  const [ImageAgreementUrl, setImageAgreementUrl] = useState("");
  const [CopyInsurance, setCopyInsurance] = useState("");
  const [ProfileError, setProfileError] = useState(false);

  const [CopyInsuranceeURL, setCopyInsuranceeURL] = useState("");
  const handleFileChangeStateInsurance = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCopyInsuranceeURL(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setCopyInsurance(reader.result);
      };
      reader.readAsDataURL(file); // Convert file to Base64
    }
  };
  const [genderError, setGenderError] = useState(false);
  const [preferredGenderError, setPreferredGenderError] = useState(false);
  const [TrainingCertificate, setTrainingCertificate] = useState("");
  const [TrainingCertificateURL, setTrainingCertificateURL] = useState("");

  const handleFileChangeTrainingCertificate = (event) => {
    const file = event.target.files[0];
    if (file) {
      setTrainingCertificateURL(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setTrainingCertificate(reader.result);
      };
      reader.readAsDataURL(file); // Convert file to Base64
    }
  };

  const handleImageUploadAgreement = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageAgreementUrl(file.name)
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64ImageAgreement(base64String); // Save Base64 data
        setAgreement(true); // Update agreement state
      };
      reader.readAsDataURL(file); // Reads the file as Base64
    }
    setAgreementError(false)
  };

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const preferredGenderOptions = [
    { value: "Male", label: "Male" },
    { value: "Non-Binary", label: "Non-Binary" },
    { value: "Intersex", label: "Intersex" },
    { value: "Trans Woman", label: "Trans Woman" },
    { value: "Trans Man", label: "Trans Man" },
    { value: "Prefer Not to Say", label: "Prefer Not to Say" },
  ];

  const ethnicityOptions = [
    { value: "Hispanic or Latino", label: "Hispanic or Latino" },
    { value: "Not Hispanic or Latino", label: "Not Hispanic or Latino" },
  ];
  const [selectedState, setSelectedState] = useState(null);
  const [LicenseState, setLicenseState] = useState(null);


  const states = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
  ];
  const [selectedImage, setSelectedImage] = useState(null);

  const [base64Image, setBase64Image] = useState("");
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setProfileError(false)
  };
  const [gender, setGender] = useState(null);
  const [preferredGender, setPreferredGender] = useState(null);
  const [ethnicity, setEthnicity] = useState(null);
  const [OtherField, setOtherField] = useState(false);
  const [loading, setLoading] = useState(false);
  const HandleCloseModal = () => {
    navigate('/login');
  };
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const onSubmit = (data) => {
    let postData = {
      ...data,
      type: 'Supervisor',
      state: selectedState,
      profilePictureURL: selectedImage,
      profilePictureBase64: base64Image,
      gender: gender,
      preferredGender: preferredGender,
      ethnicity: ethnicity,
      licenseState: LicenseState,
      uploadStateLicenseBase64: stateLicenseBase64,
      uploadStateLicenseURL: stateLicenseURL,
      pdf: base64ImageAgreement,
      uploadTrainingCertificateURL: TrainingCertificateURL,
      uploadTrainingCertificateBase64: TrainingCertificate,
      uploadCopyofInsuranceBase64: CopyInsurance,
      uploadCopyofInsuranceURL: CopyInsuranceeURL,
      password: md5(data.password),
    };

    console.log('OnSubmit Clicked', postData);

    axios
      .post(`${process.env.REACT_APP_API_URL}/Users/SignUp`, postData  ,{ headers })
      .then((response) => {
        Swal.fire({
          title: 'Success!',
          text: 'Record Submitted Successfully to Mavesory!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        }).then(() => {
          HandleCloseModal(); 
        });
      })
      .catch((error) => {
        console.error('Error submitting data:', error);
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong. Please try again.',
          icon: 'error',
          showConfirmButton: true,
        });
      });
  };
  const handleNextClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (showForm == 0) {
      if (!selectedImage) {
        setLoading(false);
        setProfileError(true)
        return;
      }
    }else if (showForm == 1) {
      if (!gender) {
        setGenderError(true)
        setLoading(false);
        return;
      }
      if (!preferredGender) {
        setPreferredGenderError(true)
        setLoading(false);
        return;
      }
    }else if (showForm == 2) {
      const isValid = await trigger("licenseNumber") && await trigger("initialLicensureDate") && await trigger("licenseExpirationDate");
      if (!isValid) {
        setLoading(false);
        return;
      }
    }else if (showForm == 3) {
      const isValid = await trigger("supervisortrainingtitle");
      if (!isValid) {
        setLoading(false);
        return;
      }
    } 
    else if (showForm == 4) {
      const isValid = await trigger("liabilityInsuranceCarrier") && await trigger("policyNumber");
      if (!isValid) {
        setLoading(false);
        return;
      }
    } 
    else if (showForm == 5) {
      if (!base64ImageAgreement) {
        setAgreementError(true)
        setLoading(false);
        return;
      }
    }
    if (showForm < 5) {
      // setShowForm(showForm + 1);
      setTimeout(() => {
          setShowForm(showForm + 1);
          setLoading(false);
        }, 500)
    } else if (showForm == 5) {
      onSubmit(getValues());
    }
  };
  const handleBackClick = () => {
    if (showForm > 0) {
      setShowForm(showForm - 1);
    }
  };
  return (
    <React.Fragment>
      <style>{animations}</style>
      <Container maxWidth={false} style={styles.container}>
        <div style={styles.backgroundImageContainer}></div>
        {loading && <LinearProgress style={{ width: '100%', position: 'absolute', top: 0, color: 'green' }} />}
        {showForm === 0 && (
          <>
            <Box style={styles.innerContainer}>
              <IconButton
                disabled={showForm == 0}
                onClick={handleBackClick} 
                style={{
                  backgroundColor: "#8C7A63",
                  color: "white",
                  border: "3px solid #fff",
                  padding: "6px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, background-color 0.2s",
                }}
              >
                <ArrowBack />
              </IconButton>


              <Typography
                mb={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                UPLOAD PROFILE PICTURE
              </Typography>
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  margin: "20px auto",
                  backgroundColor: "#fff",
                  border: '2px dashed grey',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                {selectedImage ? (
                  <img
                    src={base64Image}
                    alt="Uploaded Preview"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                ) : (
                  <label style={{ cursor: "pointer" }}>
                    Please Select Picture

                  </label>
                )}
              </div>
              <div
                style={{
                  margin: "20px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  id="fileInput"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                />
                <IconButton>
                  <FileDownloadOutlinedIcon
                    onClick={() => document.getElementById('fileInput').click()}
                    fontSize="large" color="black" cursor="pointer" fontWeight='bold' />
                </IconButton>
              </div>
              {ProfileError && (
                <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px',textAlign:'center'}}>
                  Please Select Picture
                </Typography>
              )}


              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 1 && (
          <>
            <Box style={styles.innerContainer}>
              <IconButton
                disabled={showForm == 0}
                style={{
                  backgroundColor: "#8C7A63",
                  color: "white",
                  border: "3px solid #fff",
                  padding: "6px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, background-color 0.2s",
                }}
              >
                <ArrowBack onClick={handleBackClick} />
              </IconButton>
              <Grid container spacing={1} style={{
                height: '444px', overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#aec3b0 transparent',
                '&::-webkit-scrollbar': {
                  width: 8,
                  height: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#aec3b0',
                  borderRadius: 10,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },

                '&::-webkit-scrollbar-thumb:horizontal': {
                  width: 4,
                },
              }}>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                    Gender
                  </Typography>
                  <Select
                    options={genderOptions}
                    value={genderOptions.find(option => option.label === gender)}
                    onChange={(option) => {
                      setGender(option.label);
                    }}
                    placeholder="Gender"


                  />
                  {genderError && (
                    <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                      Please Select Gender
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                    Preferred Gender
                  </Typography>
                  <Select
                    options={preferredGenderOptions}
                    value={preferredGenderOptions.find(option => option.label === preferredGender)}
                    onChange={(option) => {
                      setPreferredGender(option.label);
                    }}
                    placeholder="Preferred Gender"


                  />
                  {preferredGenderError && (
                    <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                      Please Select Preferred Gender
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                    Ethnicity
                  </Typography>
                  <Select
                    options={ethnicityOptions}
                    value={ethnicityOptions.find(option => option.label === ethnicity)}
                    onChange={(option) => {
                      setEthnicity(option.label);
                    }}
                    placeholder="Ethnicity"


                  />
                </Grid>

                <Box
                  sx={{
                    backgroundColor: "#aec3b0",
                    borderRadius: "10px",
                    padding: "10px",
                    marginTop: "10px",
                    maxWidth: "600px",
                  }}
                >
                  <Typography style={{ color: "black", fontWeight: "bold", textAlign: 'left', marginBottom: '3px', textTransform: 'uppercase' }}>
                    Race
                  </Typography>
                  <Grid container spacing={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                    <Grid item xs={12} >
                      <input
                        name='black'
                        size='small'
                        type='checkbox'
                        {...register('black')}
                        id='black'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black' }}>Black or African American</lable>
                    </Grid>
                    <Grid item xs={12} >
                      <input
                        name='white'
                        size='small'
                        type='checkbox'
                        {...register('white')}
                        id='white'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black' }}>White</lable>
                    </Grid>
                    <Grid item xs={12} >
                      <input
                        name='americanIndian'
                        size='small'
                        type='checkbox'
                        {...register('americanIndian')}
                        id='americanIndian'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black' }}>American Indian or Alaska Native</lable>
                    </Grid>
                    <Grid item xs={12} style={{ whiteSpace: 'nowrap', }} >
                      <input
                        name='nativeHawaiian'
                        size='small'
                        type='checkbox'
                        {...register('nativeHawaiian')}
                        id='nativeHawaiian'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black' }}>Native Hawaiian or Other Pacific Islander</lable>
                    </Grid>
                    <Grid item xs={12} >
                      <input
                        name='asian'
                        size='small'
                        type='checkbox'
                        {...register('asian')}
                        id='asian'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black' }}>Asian</lable>
                    </Grid>
                    <Grid item xs={12} style={{ whiteSpace: 'nowrap', }} >
                      <input
                        name='middleEastern'
                        size='small'
                        type='checkbox'
                        {...register('middleEastern')}
                        id='middleEastern'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black' }}>Middle Eastern or North African (MENA)</lable>
                    </Grid>
                    <Grid item xs={12} >
                      <input
                        name='multiracial'
                        size='small'
                        type='checkbox'
                        {...register('multiracial')}
                        id='multiracial'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black' }}>Multiracial</lable>
                    </Grid>
                    <Grid item xs={12} >
                      <input
                        name='other'
                        size='small'
                        type='checkbox'
                        {...register('other')}
                        id='other'
                        style={{
                          backgroundColor: "#8C7A63",
                          color: "#8C7A63"

                        }}
                        onClick={() => setOtherField(true)}
                      />
                      <lable style={{ marginLeft: '3px', color: 'black' }}>Other </lable>
                      {OtherField && (
                        <Controller
                          name="others"
                          {...register("others")}
                          control={control}
                          render={({ field }) =>
                            <TextField
                              size="small"
                              placeholder="other..."
                              InputProps={{
                                style: { backgroundColor: "#8C7A63", color: "#fff", height: '40px' },
                              }}
                              {...field}
                              onChange={(e) => {
                                setValue('others', e.target.value);
                              }}
                            />}
                        />
                      )}
                    </Grid>

                  </Grid>
                </Box>
              </Grid>
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 2 && (
          <>
            <Box style={styles.innerContainer}>
              <IconButton
                disabled={showForm == 0}
                style={{
                  backgroundColor: "#8C7A63",
                  color: "white",
                  border: "3px solid #fff",
                  padding: "6px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, background-color 0.2s",
                }}
              >
                <ArrowBack onClick={handleBackClick} />
              </IconButton>
              <Grid container spacing={1} style={{
                height: '444px', overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#aec3b0 transparent',
                '&::-webkit-scrollbar': {
                  width: 8,
                  height: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#aec3b0',
                  borderRadius: 10,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },

                '&::-webkit-scrollbar-thumb:horizontal': {
                  width: 4,
                },
              }}>
                <Box
                  sx={{
                    backgroundColor: "#aec3b0",
                    borderRadius: "10px",
                    padding: "10px",
                    marginTop: "10px",
                    maxWidth: "600px",
                  }}
                >
                  <Typography style={{ color: "black", fontWeight: "bold", textAlign: 'left', marginBottom: '3px' }}>
                    LICENSED TYPE
                  </Typography>
                  <Grid container spacing={1} style={{ textAlign: 'left' }}>
                    <Grid item xs={12} >
                      <input
                        name='lpcSupervisor'
                        size='small'
                        type='checkbox'
                        {...register('lpcSupervisor')}
                        id='lpcSupervisor'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>LPC-Supervisor</lable>
                    </Grid>
                    <Grid item xs={12} >
                      <input
                        name='lcswSupervisor'
                        size='small'
                        type='checkbox'
                        {...register('lcswSupervisor')}
                        id='lcswSupervisor'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>LCSW- Supervisor</lable>
                    </Grid>
                    <Grid item xs={12} >
                      <input
                        name='lmftSupervisor'
                        size='small'
                        type='checkbox'
                        {...register('lmftSupervisor')}
                        id='lmftSupervisor'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>LMFT- Supervisor</lable>
                    </Grid>
                    <Grid item xs={12} style={{ whiteSpace: 'nowrap', }} >
                      <input
                        name='bcba'
                        size='small'
                        type='checkbox'
                        {...register('bcba')}
                        id='bcba'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase', whiteSpace: 'nowrap', }}>BCBA or BCBA-D</lable>
                    </Grid>
                    <Grid item xs={12} >
                      <input
                        name='licensedSpecialist'
                        size='small'
                        type='checkbox'
                        {...register('licensedSpecialist')}
                        id='licensedSpecialist'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>Licensed Specialist in School Psychology</lable>
                    </Grid>
                    <Grid item xs={12} mt={1} >
                      <input
                        name='psychologist'
                        size='small'
                        type='checkbox'
                        {...register('psychologist')}
                        id='psychologist'
                      />
                      <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}> Psychologist</lable>
                    </Grid>
                  </Grid>
                </Box>

                <Grid container spacing={1} mt={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                  <Grid item xs={6}>
                    <InputLabel>License State</InputLabel>
                    <Select
                      options={states}
                      value={states.find(option => option.label === LicenseState)}
                      onChange={(option) => {
                        setLicenseState(option.label);
                      }}
                      placeholder="License State"

                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>License Number</InputLabel>
                    <Controller
                      name="licenseNumber"
                      {...register("licenseNumber")}
                      control={control}
                      render={({ field }) =>
                        <TextField
                          size="small"
                          placeholder="License Number"
                          InputProps={{
                            style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                          }}
                          {...field}
                          fullWidth
                          onChange={(e) => {
                            setValue('licenseNumber', e.target.value);
                          }}
                          error={!!errors.licenseNumber}
                          helperText={errors.licenseNumber?.message}
                        />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Initial Licensure Date</InputLabel>
                    <Controller
                      name="initialLicensureDate"
                      {...register("initialLicensureDate")}
                      control={control}
                      render={({ field }) =>
                        <TextField
                          size="small"
                          placeholder="Initial Licensure Date"
                          type="date"
                          InputProps={{
                            style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                          }}
                          {...field}
                          fullWidth
                          onChange={(e) => {
                            setValue('initialLicensureDate', e.target.value);
                          }}
                          error={!!errors.initialLicensureDate}
                          helperText={errors.initialLicensureDate?.message}
                        />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>License Expiration Date</InputLabel>
                    <Controller
                      name="licenseExpirationDate"
                      {...register("licenseExpirationDate")}
                      control={control}
                      render={({ field }) =>
                        <TextField
                          size="small"
                          placeholder="License Expiration Date"
                          type="date"
                          InputProps={{
                            style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                          }}
                          {...field}
                          fullWidth
                          onChange={(e) => {
                            setValue('licenseExpirationDate', e.target.value);
                          }}
                          error={!!errors.licenseExpirationDate}
                          helperText={errors.licenseExpirationDate?.message}
                        />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Upload State License</InputLabel>
                    <input
                      type="file"
                      accept="image/*,application/pdf"
                      id="Licence"
                      onChange={handleFileChangeState}
                      style={{ display: "none" }}
                    />
                    <TextField
                      variant="outlined"
                      // type="file"
                      placeholder="Upload State License"
                      value={stateLicenseURL}
                      InputProps={{
                        endAdornment: <FileDownloadOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => document.getElementById("Licence").click()} />,
                        style: { backgroundColor: "#aec3b0", color: "black", textAlign: 'left', height: '40px' },
                      }}
                      fullWidth
                    />

                  </Grid>
                </Grid>
              </Grid>
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 3 && (
          <>
            <Box style={styles.innerContainer}>
              <IconButton
                disabled={showForm == 0}
                style={{
                  backgroundColor: "#8C7A63",
                  color: "white",
                  border: "3px solid #fff",
                  padding: "6px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, background-color 0.2s",
                }}
              >
                <ArrowBack onClick={handleBackClick} />
              </IconButton>
              <Grid container spacing={1} mt={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                <Grid item xs={12}>
                  <InputLabel>Name of Supervisor Training</InputLabel>
                  <Controller
                    name="supervisortrainingtitle"
                    {...register("supervisortrainingtitle")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Name of Supervisor Training"
                        InputProps={{
                          style: { backgroundColor: "#aec3b0", color: "black" },
                        }}
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('supervisortrainingtitle', e.target.value);
                        }}
                        error={!!errors.supervisortrainingtitle}
                        helperText={errors.supervisortrainingtitle?.message}
                      />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Date of Completed Training</InputLabel>
                  <Controller
                    name="dateofCompletedTraining"
                    {...register("dateofCompletedTraining")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        type="date"
                        InputProps={{
                          style: { backgroundColor: "#aec3b0", color: "black" },
                        }}
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('dateofCompletedTraining', e.target.value);
                        }}
                      />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Upload Training Certificate</InputLabel>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    id="INSURANCE"
                    onChange={handleFileChangeTrainingCertificate}
                    style={{ display: "none" }}
                  />
                  <TextField
                    variant="outlined"
                    // type="file"
                    placeholder="Upload Insurance Certificate"
                    value={TrainingCertificateURL}
                    InputProps={{
                      endAdornment: <FileDownloadOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => document.getElementById("INSURANCE").click()} />,
                      style: { backgroundColor: "#aec3b0", color: "black", textAlign: 'left', height: '40px' },
                    }}
                    fullWidth
                  />

                </Grid>
              </Grid>
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 4 && (
          <>
            <Box style={styles.innerContainer}>
              <IconButton
                disabled={showForm == 0}
                style={{
                  backgroundColor: "#8C7A63",
                  color: "white",
                  border: "3px solid #fff",
                  padding: "6px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, background-color 0.2s",
                }}
              >
                <ArrowBack onClick={handleBackClick} />
              </IconButton>
              <Grid container spacing={1} mt={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                <Grid item xs={12}>
                  <InputLabel>Liability Insurance Carrier</InputLabel>

                  <Controller
                    name="liabilityInsuranceCarrier"
                    {...register("liabilityInsuranceCarrier")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Liability Insurance Carrier"
                        InputProps={{
                          style: { backgroundColor: "#aec3b0", color: "black" },
                        }}
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('liabilityInsuranceCarrier', e.target.value);
                        }}
                        error={!!errors.liabilityInsuranceCarrier}
                        helperText={errors.liabilityInsuranceCarrier?.message}
                      />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Policy Number</InputLabel>
                  <Controller
                    name="policyNumber"
                    {...register("policyNumber")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Policy Number"
                        InputProps={{
                          style: { backgroundColor: "#aec3b0", color: "black" },
                        }}
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('policyNumber', e.target.value);
                        }}
                        error={!!errors.policyNumber}
                        helperText={errors.policyNumber?.message}
                      />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Insurance Policy Start Date</InputLabel>
                  <Controller
                    name="insurancePolicyStartDate"
                    {...register("insurancePolicyStartDate")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        type="date"
                        InputProps={{
                          style: { backgroundColor: "#aec3b0", color: "black" },
                        }}
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('insurancePolicyStartDate', e.target.value);
                        }}
                      />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Insurance Policy End Date</InputLabel>
                  <Controller
                    name="insurancePolicyEndDate"
                    {...register("insurancePolicyEndDate")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        type="date"
                        InputProps={{
                          style: { backgroundColor: "#aec3b0", color: "black" },
                        }}
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('insurancePolicyEndDate', e.target.value);
                        }}
                      />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Upload Copy of Insurance</InputLabel>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    id="INSURANCE"
                    onChange={handleFileChangeStateInsurance}
                    style={{ display: "none" }}
                  />
                  <TextField
                    variant="outlined"
                    // type="file"
                    placeholder="Upload Insurance Certificate"
                    value={CopyInsuranceeURL}
                    InputProps={{
                      endAdornment: <FileDownloadOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => document.getElementById("INSURANCE").click()} />,
                      style: { backgroundColor: "#aec3b0", color: "black", textAlign: 'left', height: '40px' },
                    }}
                    fullWidth
                  />

                </Grid>
              </Grid>
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
        {showForm === 5 && (
          <>
            <Box style={styles.innerContainer}>
              <IconButton
                disabled={showForm == 0}
                style={{
                  backgroundColor: "#8C7A63",
                  color: "white",
                  border: "3px solid #fff",
                  padding: "6px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.2s, background-color 0.2s",
                }}
              >
                <ArrowBack onClick={handleBackClick} />
              </IconButton>
              <Box
                sx={{
                  backgroundColor: "#aec3b0",
                  borderRadius: "10px",
                  padding: "20px",
                  margin: "auto",
                  textAlign: "center",
                }}
                id="Data-container-6"
              >
                <Typography
                  mb={2}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                    textTransform: 'uppercase'
                  }}
                >
                  Complete User Agreement
                </Typography>
                <div
                  style={{
                    width: "250px",
                    height: "250px",
                    margin: "20px auto",
                    backgroundColor: "#fff",
                    border: '2px dashed grey',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  {Agreement ? (
                    <iframe
                      src={base64ImageAgreement}
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                  ) : (
                    <label style={{ cursor: "pointer" }}>
                      Please Upload Signed User Agreement

                    </label>
                  )}
                </div>
                <div
                  style={{
                    margin: "20px auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <input
                    type="file"
                    accept="application/pdf"
                    id="fileInput"
                    onChange={handleImageUploadAgreement}
                    style={{ display: "none" }}
                  />
                  <IconButton>
                    <FileDownloadOutlinedIcon
                      onClick={() => document.getElementById('fileInput').click()}
                      fontSize="large" color="black" cursor="pointer" fontWeight='bold' />
                  </IconButton>
                </div>
                {AgreementError && (
                  <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                    Please Select User Agreement
                  </Typography>
                )}
                <Grid container spacing={1}>
                  <Grid item xs={12} >
                    <input
                      name='userAggreementCheck'
                      size='small'
                      type='checkbox'
                    />
                    <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>I Agree To The User Agreement</lable>
                  </Grid>
                  {/* <Grid item xs={12}>
                <Typography style={{ color: 'balck', fontSize: '19px',marginLeft:'5px',textAlign:'left'}}>
                   Signature
                  </Typography>
                </Grid> */}
                  <Grid item xs={12}>
                    <Controller
                      name="others"
                      {...register("others")}
                      control={control}
                      render={({ field }) =>
                        <TextField
                          size="small"
                          placeholder="Sign Here :"
                          multiline
                          rows={2}
                          InputProps={{
                            style: { backgroundColor: "#fff", color: "black" },
                          }}
                          {...field}
                          fullWidth
                          onChange={(e) => {
                            setValue('others', e.target.value);
                          }}
                        />
                      }
                    /></Grid>
                </Grid>
              </Box>
              <Button style={styles.nextButton} onClick={handleNextClick} fullWidth>
                {showForm === 5 ? 'SAVE' : 'NEXT'}
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Box>
          </>
        )}
      </Container>
    </React.Fragment >
  );
}
