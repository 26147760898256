import React from 'react';
import {
  Box, Typography, Button, Container, Grid, InputLabel, TextField, LinearProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from "axios";
import Swal from 'sweetalert2';
export default function SubscriptionConfirmationPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const handleNavigateBack = () => {
    navigate('/SubscriptionFormPage');
  };

  // Inline styles for Subscription Confirmation Page
  const styles = {
    container: {
      minHeight: '100vh',
      // backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      position: 'relative',
    },
    backgroundImageContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      backgroundImage: 'url("/mavesory.png")',
      backgroundSize: '45%',
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      zIndex: -3,
    },
    innerContainer: {
      backgroundColor: '#fff',
      borderRadius: '20px',
      padding: '40px',
      // boxShadow: '10 4px 6px rgba(0, 0, 0, 0.1)',
      boxShadow: '10px 4px 6px rgba(0, 0, 0, 0.1)',
      maxWidth: '500px',
      width: '100%',
      textAlign: 'center',
      borderTop: '3px solid rgba(0, 0, 0, 0.1)',
    },
    formTitle: {
      color: '#2C3E2D',
      marginBottom: '20px',
      fontWeight: 'bold',
    },
    emailField: {
      backgroundColor: '#fff',
      color: 'black',
      height: '40px',
    },
    cardContainer: {
      padding: '15px',
      background: '#FFFFFF',
      border: '1px solid #E0E0E0',
      borderRadius: '8px',
      margin: '3px',
      marginBottom: '20px',
    },
    payButton: {
      background: '#8C7A63',
      color: '#FFFFFF',
      fontSize: '16px',
      padding: '10px 20px',
      border: 'none',
      height: '40px',
      maxWidth: '200px',
      borderRadius: '5px',
      fontWeight: 'bold',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
    },
    nextButton: {
      background: '#7A9483',
      color: 'white',
      padding: '10px 30px',
      borderRadius: '20px',
      marginTop: '20px',
      '&:hover': {
        background: '#658570',
      },
    },
  };
  const handleNextPage = (event) => {
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate('/SupervisorSignupDetails');
    }, 500); // Delay for animation effect
  }
  const stripe = useStripe();
  const elements = useElements();
  const emailofUser = localStorage.getItem("SignUpuserEmail")
  const handleSubmitPay = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);
    try {
      const { error, token } = await stripe.createToken(cardElement, { currency: 'usd' });
      if (error) {
        setLoading(false);
        return;
      }
      console.log('Token', token.id);
      const jwtToken = token.id;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Stripe/add-card-to-customer`,
        {
          email: emailofUser,
          sourceToken: token.id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Card added successfully!',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.message || 'Failed to add the card. Please try again.',
        });
      }
    } catch (apiError) {
      console.error('[API Error]', apiError);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: apiError.response?.data?.message || 'An unexpected error occurred.',
      });
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <React.Fragment>
      <Container maxWidth={false} style={styles.container}>
        <div style={styles.backgroundImageContainer}></div>
        {loading && <LinearProgress style={{ width: '100%', position: 'absolute', top: 0, color: 'green' }} />}
        <Box style={styles.innerContainer}>
          <Typography variant="h5" style={styles.formTitle}>
            Subscription
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} mb={2}>
              <InputLabel style={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>Email</InputLabel>
              <TextField
                size="small"
                placeholder="Enter Email"
                InputProps={{
                  readOnly: true,
                  style: styles.emailField,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={11.9} style={styles.cardContainer}>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#9E9E9E',
                      },
                    },
                    invalid: {
                      color: '#F44336',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} mt={3}>
              <Button
                fullWidth
                style={styles.payButton}
                disabled={!stripe}
                onClick={handleSubmitPay}
              >
                Pay
              </Button>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            fullWidth
            style={styles.nextButton}
            onClick={handleNextPage}
          // disabled={!selectedPackage} // Disable if no package is selected
          >
            NEXT
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
}
