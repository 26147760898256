import { useState, useEffect, useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import UploadIcon from '@mui/icons-material/Upload';
import RadioGroup from "@mui/material/RadioGroup";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Documents from "./Documents";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FlashlightOnRounded } from "@mui/icons-material";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SignInIcon from '@mui/icons-material/PersonAdd';


const IncidentRegistration = props => {
  const fields = [
    "id",
    "practiceId",
    "supervisorFirstName",
    "supervisorLastName",
    "dateTime",
    "starTtime",
    "endTime",
    "incidentType",
    "supervisionType",
    "detailOfIncident",
    "witnesses",
    "supervisorFollowUp",
    "superviseeGoals",
    "therapistsId",
    "therapistsName",
    "ethicalIssues",
    "concernEthics",
    "therapistTimeStamp",
    "approvedDateTime",
     "superviseeSign",
     "supervisorSign",
      "documentName",
      "base64",
      "base64URL",
      "approveDateTime"

  ];
  const defValues = {
    id: 0,
    practiceId: 0,
    therapistsId: 0,
    supervisorFirstName: "",
    supervisorLastName: "",
    dateTime: "",
    starTtime: "",
    endTime: "",
    incidentType: "",
    supervisionType: "",
    detailOfIncident: "",
    witnesses: "",
    supervisorFollowUp: "",
    superviseeGoals: "",
    therapistsName: '',
    ethicalIssues: '',
    concernEthics: '',
    therapistTimeStamp: '',
    approvedDateTime: '',
    superviseeSign:'',
    supervisorSign:'',
    base64URL:'',
    base64:'',
    documentName:"",
    approveDateTime:''

  };
  const [date, setDate] = React.useState('')
  const [StartTime, setStartTime] = useState('')
  const [StartTimeError, setStartTimeError] = useState(false)

  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const Incidentid = props.uid;


  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const UserType = localStorage.getItem("User_Type")
  const UserName = localStorage.getItem("UserName")
  console.log('UserType', UserType)
  const validationSchema = Yup.object({
    dateTime: Yup.date()
      .nullable()
      .required("Please select a date")
      .typeError("Please select a date"),
    incidentType: Yup.string().required("Please Select a Type"),
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [approve, setapprove] = useState(false);
  const [Approval, setApproval] = useState(false);
  const [therapistsIdError, setTherapistsIdError] = React.useState(false);
  const [Data, setData] = useState([])
  const [incidentId, setIncidentId] = useState([])
  const [file, setFile] = useState(null)
  const [documentName, setDocumentName] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Read the file as Base64
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setFile(reader.result);
        setDocumentName(selectedFile.name); // Save the file name
      };
    }
  };
  const [SupervisorSignError, setSupervisorSignError] = useState(false);
  const [therapistSignError, settherapistSignError] = useState(false);

  const handleSignInSupervisor = () => {
    setValue('supervisorSign', UserName);
    setSupervisorSignError(false)
  };

  const handleSignInSupervisee = () => {
    setValue('superviseeSign', UserName);
    settherapistSignError(false)

  };
  const onSubmit = data => {
    let postData = data;
    const CombineTime = `${date}T${StartTime}`;
    postData.dateTime = date
    postData.therapistsName = TherapistsName
    postData.therapistsId = TherapistID
    postData.createdDate = CreatedDate
    postData.starTtime = CombineTime
    if (!StartTime) {
      setStartTimeError(true)
      setSubmitting(false)
      return;
    }
    if (!TherapistID) {
      setTherapistsIdError(true)
      setSubmitting(false)
      return;
    }
    if (file) {
      postData.base64URL = file;
      postData.documentName = documentName;
    }
    setSubmitting(true);
    if (UserType == 'Therapist') {
      postData.pendingApproval = true
      postData.therapistTimeStamp = Moment().format('YYYY-MM-DD HH:mm');
      if (!data.superviseeSign) {
        settherapistSignError(true);
        setSubmitting(false);
        return; 
      }
    }
    if (UserType == 'Supervisor') {
      postData.approve = true
      postData.approvedBy = UserName
      postData.approveDateTime = Moment().format('YYYY-MM-DD HH:mm');
      if (!data.supervisorSign) {
        setSupervisorSignError(true);
        setSubmitting(false);
        return; 
      }
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/IncidentReport/AddIncidentReport`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          toast.success('Record saved successfully', {
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

            onClose: () => {
              props.handleClose()
            }

          });
          // alert.success("Record saved successfully.", {
          //   type: "success",
          //   onClose: () => {
          //     navigate("/IncidentReporting");
          //   }
          // });
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const [base64Url, setbase64Url] = useState('');
  const [DOCName, setDOCName] = useState('');

  useEffect(() => {
    if (Incidentid) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReportById?id=${Incidentid}`, {
          headers
        })
        .then(response => {
          // setEmergencycontantid(response.id)
          fields.forEach(field => {
            setValue(field, response[field]);
          });
          var dateYMD = Moment(response.dateTime).format('YYYY-MM-DD')
          setDate(dateYMD)
          // var dateYMD23 = Moment(response.starTtime).format('HH:mm');
          // setStartTime(dateYMD23);
          if (response.starTtime) {
            const formattedStartTime = Moment(response.starTtime, 'HH:mm').format('HH:mm');
            setStartTime(formattedStartTime);  // Set time if available
          } else {
            console.warn('Start time not found or invalid'); // Debug message
          }
          setapprove(response.approve)
          setApproval(response.pendingApproval)
          setData(response)
          setCreatedDate(response.createdDate)
          setEthicalIssue(response.ethicalIssues)
          setbase64Url(response.base64URL)
          setDOCName(response.documentName)
          setTherapistID(response.therapistsId)
          setTherpistName(response.therapistsName)


        })
        .catch(error => { });
    }
  }, [Incidentid]);

  function handleCancel() {
    navigate("/IncidentReporting");
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#377562',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  }
  const [TherapistID, setTherapistID] = useState("");
  const [TherapistsName, setTherpistName] = useState("");
  const [EthicalIssue, setEthicalIssue] = useState("");
  const [CreatedDate, setCreatedDate] = useState("");
  const [TherapistList, setTherapistList] = useState([]);

  useEffect(() => {
    if (UserType !== 'Supervisor') {
      axios
        .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: field.userName,
              value: field.id
            });
          });
          setTherapistList(prac);
        })
        .catch(error => { });
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/GetUsersBySupervisorId?supervisorId=${Userid}&userType=${'Therapist'}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: field.userName,
              value: field.id
            });
          });
          setTherapistList(prac);
        })
        .catch(error => { });
    }
  }, []);
  const handleDownload = async () => {
    const doc = new jsPDF();

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setDrawColor(25, 42, 86);
    doc.setLineWidth(0.2);
    doc.rect(5, 5, pageWidth - 10, pageHeight - 10);

    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 102, 204); // Set title color to RGB(0, 102, 204)
    const title = 'Incident Registration Report';
    const titleWidth = doc.getTextWidth(title);
    doc.text(title, (pageWidth - titleWidth) / 2, 20);

    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0);

    const justifyText = (text, x, y, maxWidth) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      doc.text(lines, x, y);
    };


    const formattedDate = Moment(Data.dateTime).format('MM-DD-YYYY');
    const formattedApprovedTime = Data.approveDateTime
      ? Moment(Data.approveDateTime).format('MM-DD-YYYY [at] HH:mm')
      : 'N/A';

    const formattedTherapistTime = Data.therapistTimeStamp
      ? Moment(Data.therapistTimeStamp).format('MM-DD-YYYY [at] HH:mm')
      : 'N/A';

    const startY = 40;

    doc.autoTable({
      startY: startY,
      theme: 'grid',
      headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255], fontStyle: 'bold' },
      styles: { lineWidth: 0.2, lineColor: [0, 0, 0] },
      body: [
        [
          { content: 'Date', styles: { halign: 'center', fontStyle: 'bold', textDecoration: 'underline' } },
          { content: formattedDate, styles: { halign: 'center' } },
          { content: 'Time', styles: { halign: 'center', fontStyle: 'bold' } },
          { content: Data.starTtime, styles: { halign: 'center' } } // Corrected typo 'starTtime' to 'startTime'
        ],
        [
          { content: 'Incident Type', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: Data.incidentType || 'N/A', colSpan: 4, styles: { halign: 'left' } } // Default to 'N/A' if undefined
        ],
        [
          { content: 'Detail Of Incident', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: Data.detailOfIncident || 'N/A', colSpan: 4, styles: { halign: 'left' } }
        ],
        [
          { content: 'Therapist Name', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: Data.therapistsName || 'N/A', colSpan: 4, styles: { halign: 'left' } }
        ],
        [
          { content: 'Witness', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: Data.witnesses || 'N/A', colSpan: 4, styles: { halign: 'left' } }
        ],
        [
          { content: 'Supervision Goals', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: Data.superviseeGoals || 'N/A', colSpan: 4, styles: { halign: 'left' } }
        ],
        [
          { content: 'Supervision Follow Up', colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }
        ],
        [
          { content: Data.supervisorFollowUp || 'N/A', colSpan: 4, styles: { halign: 'left' } }
        ],
        [
          { content: 'Therapist Name', styles: { halign: 'center', fontStyle: 'bold', textDecoration: 'underline' } },
          { content: Data.therapistsName || 'N/A', styles: { halign: 'center' } },
          { content: 'Therapist ID', styles: { halign: 'center', fontStyle: 'bold' } },
          { content: Data.therapistsId || 'N/A', styles: { halign: 'center' } }
        ],
        [
          {
            content: 'Signed By Supervisor:' + Data.supervisorSign,
            colSpan: 4,
            styles: { halign: 'left', fontStyle: 'bold' }
          }
        ],
        [
          {
            content: 'Signed On :' + formattedApprovedTime,
            colSpan: 4,
            styles: { halign: 'left', fontStyle: 'bold' }
          }
        ],
        [
          {
            content: 'Signed By Supervisee:' + Data.superviseeSign,
            colSpan: 4,
            styles: { halign: 'left', fontStyle: 'bold' }
          }
        ],
        [
          {
            content: 'Signed On :' + formattedTherapistTime,
            colSpan: 4,
            styles: { halign: 'left', fontStyle: 'bold' }
          }
        ],
      ],
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 50 },
        2: { cellWidth: 40 },
        3: { cellWidth: 50 },
      },
    });

    const fileName = `Incident_Request_Pdf_${Moment(new Date()).format('DD_MMM_YY')}.pdf`;
    doc.save(fileName);
  };
  const [modalOpenImage, setmodalOpenImage] = useState(false);
  const handleCloseImage = () => {
    setmodalOpenImage(false);
  };
  const Userid = localStorage.getItem('userid')

  useEffect(() => {
    if (UserType === "Therapist") {
      setTherapistID(Userid);
      setTherpistName(UserName);
    }
  }, [UserType, Userid]);
  return (
    <React.Fragment>
      <Grid container>

        <Modal open={modalOpenImage} onClose={handleCloseImage}>
          <Box
            sx={{
              position: 'absolute',
              top: '52%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 912,
              bgcolor: 'background.paper',
              borderColor: '#326C1D',
              border: 5,
              padding: '5px',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Grid
              container xs={12}
              style={{
                background: '#377562',
                position: 'sticky',
                marginBottom: '14px',
                padding: '8px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(54, 118, 196, 0.5)',
                display: 'flex'
              }}
            >
              <Grid item xs={11}>
                <Typography style={{
                  fontSize: '17px',
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  color: '#fff'
                }}> {DOCName} </Typography>
              </Grid>
              <Grid item xs={1}>
                <CancelOutlinedIcon
                  onClick={handleCloseImage}
                  fontSize="medium"
                  color="red"
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                    float: 'right',
                  }}
                ></CancelOutlinedIcon>
              </Grid>
            </Grid>
            <div>
              <Grid item xs={8}>
                {/* Check if base64Url is not null or undefined and then determine if it's a PDF */}
                {base64Url ? (
                  base64Url.includes("application/pdf") ? (
                    <iframe
                      height="400px"
                      width="850px"
                      style={{ border: '4px solid #000' }}
                      src={base64Url}
                      title="Document"
                    />
                  ) : (
                    <img
                      src={base64Url}
                      alt="Document"
                      style={{
                        maxHeight: '400px',
                        maxWidth: '850px',
                        objectFit: 'contain', // Ensures the image fits within the box
                        border: '4px solid #000',
                      }}
                    />
                  )
                ) : (
                  <p>No document available</p>
                )}
              </Grid>
              <Grid item xs={1}></Grid>
            </div>
            <Grid
              item
              xs={12}
              style={{
                fontWeight: 500,
                marginLeft: '30px',
                marginBottom: '25px',
                marginTop: '10px',
              }}
            >
              <Button
                className="Button"
                variant="gradient"
                onClick={handleCloseImage}
                style={{
                  color: 'white',
                  fontSize: '13px',
                  height: '27px',
                  marginBottom: '3px',
                  float: 'right',
                }}
              >
                Close
              </Button>
            </Grid>
          </Box>
        </Modal>

        <Grid container spacing={1}>
          <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
          <Grid item xs={8} sm={8} xl={8}></Grid>
          <Grid item xs={3} sm={3} xl={3}>
            <Button
              className="Button"
              onClick={handleDownload}
              fullWidth
              disabled={!Data || Data.length === 0}
              variant='contained'
              style={{
                fontSize: '14px', fontWeight: 'bold',
                float: 'right',
                marginTop: '10px'
              }}
              type="submit"
            >
              Download
              <DownloadForOfflineIcon />
            </Button>
          </Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
          {Incidentid > 0 && base64Url && base64Url.length > 0 ? (
            <>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={11} sm={11} xl={11} style={{ marginTop: '10px' }}>
                <Tooltip title="Add Document" >
                  <Button
                    className="Button"
                    fullWidth
                    variant="contained"
                    onClick={() => setmodalOpenImage(true)}
                    style={{
                      fontSize: '14px',
                      marginTop: '10px',
                      backgroundColor: '#3f51b5',
                      color: '#fff',
                      cursor: 'pointer',

                    }}
                  >
                    View Document
                    <UploadIcon style={{ marginLeft: '5px' }} />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
            </>
          ) : <Grid item xs={12} sm={12} xl={12} >
            <Typography style={{ fontSize: '14px', paddingLeft: '19px' }}>
              No Document Found
            </Typography> </Grid>
          }
          {approve == true || Approval == true ? (
            <>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Date
                </Typography>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Time
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>


              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      type="date"
                      {...field}
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      value={date ? date : ''}
                      onChange={e => {
                        setValue('dateTime', e.target.value)
                        setDate(e.target.value)
                      }}

                    />}
                />

              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="starTtime"
                  {...register("starTtime")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      type="time"
                      InputProps={{
                        readOnly: true
                      }}
                      fullWidth
                      value={StartTime ? StartTime : ''}
                      onChange={e => {
                        const selectedDate = e.target.value;
                        setValue('starTtime', selectedDate);
                        StartTime(selectedDate);
                      }}
                    />}
                />

              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Incident Type
                </Typography>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Therapist
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              {/* <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Supervision Type
                  </Typography>
                </Grid> */}
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name='incidentType'
                  margin='dense'
                  {...register('incidentType')}
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      style={{ marginTop: 0, marginBottom: 0 }}

                      {...field}
                      options={[
                        { value: 'Subpoena/Court', label: 'Subpoena/Court' },
                        { value: 'Ethics ', label: 'Ethics' },
                        { value: 'Site Incident', label: 'Site Incident' }
                      ]}
                      isDisabled={true}
                      value={{ label: getValues('incidentType') }}
                      onChange={value => setValue('incidentType', value.label)}
                      size='small'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="therapistsId"
                  // {...register("userId")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      {...field}
                      fullWidth
                      value={TherapistList.filter(function (option) {
                        return (
                          option.value == TherapistID
                        );
                      })}
                      onChange={e => {
                        setValue("therapistsId", e.value);
                        setTherapistID(e.value);
                        setValue("therapistsName", e.value)


                      }}
                      isDisabled={true}
                      options={TherapistList}
                    />

                  }
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Ethical Issues
                </Typography>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <div>
                    <input
                      type="radio"
                      value="Mornings"
                      name="ethicalIssues"
                      checked={EthicalIssue === "Yes"}
                      onClick={() => {
                        setValue("ethicalIssues", "Yes");
                        setEthicalIssue("Yes");
                      }}
                      style={{
                        // marginLeft: "30px",
                        transform: "scale(1.0)",
                        marginRight: "5px",
                      }}
                      disabled
                    />
                    {""}
                    <label style={{ fontSize: "15px", fontWeight: "normal" }}>Yes</label>
                    <input
                      type="radio"
                      value="Evenings"
                      name="ethicalIssues"
                      checked={EthicalIssue === "No"}
                      onClick={() => {
                        setValue("ethicalIssues", "No");
                        setEthicalIssue("No");

                      }}
                      style={{
                        marginLeft: "30px",
                        transform: "scale(1.0)",
                        marginRight: "5px",
                      }}
                      disabled

                    />
                    {""}
                    <label style={{ fontSize: "15px", fontWeight: "normal" }}>No</label>
                  </div>
                </RadioGroup>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              {EthicalIssue === "Yes" && (<>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                  <Typography style={{ fontSize: '14px' }}>
                    Code of Concern in Ethics
                  </Typography>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>

                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Controller
                    name='concernEthics'
                    {...register('concernEthics')}
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={3}
                        placeholder='Concern in Ethics...'
                        disabled={true}
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                          border: '2px solid #black',
                          height: '100px', // set a fixed height here
                          //   overflow: 'scroll'
                        }}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              </>)}
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Details of Incident
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='detailOfIncident'
                  {...register('detailOfIncident')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={3}
                      placeholder='enter details of incident'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      {...field}
                      fullWidth
                      disabled={true}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Witnesses
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='witnesses'
                  {...register('witnesses')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={3}
                      placeholder='enter details witnesses'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      {...field}
                      fullWidth
                      disabled={true}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Supervisor Follow Up
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              {UserType && UserType == 'Therapist' ? (
                <>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                  <Grid item xs={11} sm={11} xl={11}>
                    <Controller
                      name='supervisorFollowUp'
                      {...register('supervisorFollowUp')}
                      control={control}
                      render={({ field }) => (
                        <TextareaAutosize
                          aria-label='minimum height'
                          minRows={3}
                          placeholder='enter supervisor follow up'
                          style={{
                            width: '100%',
                            borderRadius: '8px',
                            border: '2px solid #black',
                            height: '100px', // set a fixed height here
                            //   overflow: 'scroll'
                          }}
                          {...field}
                          fullWidth
                          disabled={true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
              ) : (
                <>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                  <Grid item xs={11} sm={11} xl={11}>
                    <Controller
                      name='supervisorFollowUp'
                      {...register('supervisorFollowUp')}
                      control={control}
                      render={({ field }) => (
                        <TextareaAutosize
                          aria-label='minimum height'
                          minRows={3}
                          placeholder='enter supervisor follow up'
                          style={{
                            width: '100%',
                            borderRadius: '8px',
                            border: '2px solid #black',
                            height: '100px', // set a fixed height here
                            //   overflow: 'scroll'
                          }}
                          {...field}
                          fullWidth
                          disabled={approve}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
              )}
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Goals for Supervisee
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>


              {UserType && UserType == 'Therapist' ? (
                <>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                  <Grid item xs={11} sm={11} xl={11}>
                    <Controller
                      name='superviseeGoals'
                      {...register('superviseeGoals')}
                      control={control}
                      render={({ field }) => (
                        <TextareaAutosize
                          aria-label='minimum height'
                          minRows={3}
                          placeholder='enter goals for supervisee'
                          style={{
                            width: '100%',
                            borderRadius: '8px',
                            border: '2px solid #black',
                            height: '100px', // set a fixed height here
                            //   overflow: 'scroll'
                          }}
                          {...field}
                          fullWidth
                          disabled={true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
              ) : (
                <>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                  <Grid item xs={11} sm={11} xl={11}>
                    <Controller
                      name='superviseeGoals'
                      {...register('superviseeGoals')}
                      control={control}
                      render={({ field }) => (
                        <TextareaAutosize
                          aria-label='minimum height'
                          minRows={3}
                          placeholder='enter goals for supervisee'
                          style={{
                            width: '100%',
                            borderRadius: '8px',
                            border: '2px solid #black',
                            height: '100px', // set a fixed height here
                            //   overflow: 'scroll'
                          }}
                          {...field}
                          
                          fullWidth
                          disabled={approve}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
              )}
            </>
          ) : (
            <>
              {!base64Url ? (
                <>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                  <Grid item xs={11} sm={11} xl={11} style={{ marginTop: '10px' }}>
                    <Tooltip title="Add Document" >
                      <input
                        type="file"
                        id="fileInput"
                        onChange={handleFileChange}
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                        style={{ display: 'none' }}
                      />
                      <Button
                        onClick={() => document.getElementById('fileInput').click()}
                        disabled={submitting}
                        className="Button"
                        fullWidth
                        variant="contained"
                        style={{
                          fontSize: '14px',
                          marginTop: '10px',
                          backgroundColor: '#3f51b5',
                          color: '#fff',
                          cursor: 'pointer',

                        }}
                      >
                        Upload Document
                        <UploadIcon style={{ marginLeft: '5px' }} />
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    xl={11}
                    style={{
                      marginTop: '10px',
                      padding: '10px',
                      backgroundColor: '#f9f9f9',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <InsertDriveFileIcon style={{ color: '#3f51b5' }} />
                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: '#333' }}>
                      {documentName || "No Documents selected"}
                    </Typography>
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
              ) : ''}

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Date
                </Typography>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Time
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>


              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="dateTime"
                  control={control}
                  {...register("dateTime")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      type="date"
                      {...field}
                      fullWidth
                      value={date ? date : ''}
                      onChange={e => {
                        const selectedDate = e.target.value;
                        setValue('dateTime', selectedDate);
                        setDate(selectedDate);
                      }}

                      error={errors.dateTime}
                    />
                  }
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.dateTime?.message}</p>

              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="starTtime"
                  {...register("starTtime")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      type="time"
                      fullWidth
                      value={StartTime || ''}
                      onChange={e => {
                        const selectedDate = e.target.value;
                        setValue('starTtime', selectedDate);
                        setStartTime(selectedDate);
                        setStartTimeError(false)
                      }}

                    />}
                />
             
                {StartTimeError && <p style={{ color: 'red' }}>Please Select Time</p>}

              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Incident Type
                </Typography>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Therapist
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              {/* <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Supervision Type
                  </Typography>
                </Grid> */}
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name='incidentType'
                  margin='dense'
                  {...register('incidentType')}
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      style={{ marginTop: 0, marginBottom: 0 }}

                      {...field}
                      options={[
                        { value: 'Subpoena/Court', label: 'Subpoena/Court' },
                        { value: 'Ethics ', label: 'Ethics' },
                        { value: 'Site Incident', label: 'Site Incident' }
                      ]}
                      value={{ label: getValues('incidentType') }}
                      onChange={value => setValue('incidentType', value.label)}
                      size='small'
                      error={errors.incidentType}

                    />
                  )}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.incidentType?.message}</p>

              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="therapistsId"
                  control={control}
                  {...register('therapistsId')}
                  render={({ field }) => {
                    return (
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={TherapistList.filter(function (option) {
                          return (
                            option.value == TherapistID
                          );
                        })}
                        isDisabled={UserType === "Therapist"}
                        onChange={e => {
                          setValue("therapistsId", e.value);
                          setTherpistName(e.label);
                          setTherapistID(e.value);
                          therapistsIdError(false)
                        }}
                        options={TherapistList}
                      />
                    );
                  }}
                />
                {therapistsIdError && <p style={{ color: 'red' }}>Please Select Therapist</p>}


              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Ethical Issues
                </Typography>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <div>
                    <input
                      type="radio"
                      value="Mornings"
                      name="ethicalIssues"
                      checked={EthicalIssue === "Yes"}
                      onClick={() => {
                        setValue("ethicalIssues", "Yes");
                        setEthicalIssue("Yes");
                      }}
                      style={{
                        // marginLeft: "30px",
                        transform: "scale(1.0)",
                        marginRight: "5px",
                      }}
                    />
                    {""}
                    <label style={{ fontSize: "15px", fontWeight: "normal" }}>Yes</label>
                    <input
                      type="radio"
                      value="Evenings"
                      name="ethicalIssues"
                      checked={EthicalIssue === "No"}
                      onClick={() => {
                        setValue("ethicalIssues", "No");
                        setEthicalIssue("No");

                      }}
                      style={{
                        marginLeft: "30px",
                        transform: "scale(1.0)",
                        marginRight: "5px",
                      }}
                    />
                    {""}
                    <label style={{ fontSize: "15px", fontWeight: "normal" }}>No</label>
                  </div>
                </RadioGroup>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              {EthicalIssue === "Yes" && (<>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                  <Typography style={{ fontSize: '14px' }}>
                    Code of Concern in Ethics
                  </Typography>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>

                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Controller
                    name='concernEthics'
                    {...register('concernEthics')}
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={3}
                        placeholder='Concern in Ethics...'
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                          border: '2px solid #black',
                          height: '100px', // set a fixed height here
                          //   overflow: 'scroll'
                        }}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              </>)}



              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Details of Incident
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='detailOfIncident'
                  {...register('detailOfIncident')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={3}
                      placeholder='enter details of incident'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Witnesses
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='witnesses'
                  {...register('witnesses')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={3}
                      placeholder='enter details witnesses'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Supervisor Follow Up
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              {UserType && UserType == 'Therapist' ? (
                <>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                  <Grid item xs={11} sm={11} xl={11}>
                    <Controller
                      name='supervisorFollowUp'
                      {...register('supervisorFollowUp')}
                      control={control}
                      render={({ field }) => (
                        <TextareaAutosize
                          aria-label='minimum height'
                          readOnly={true}
                          minRows={3}
                          placeholder='enter supervisor follow up'
                          style={{
                            width: '100%',
                            borderRadius: '8px',
                            border: '2px solid #black',
                            height: '100px', // set a fixed height here
                            //   overflow: 'scroll'
                          }}
                          {...field}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
              ) : (
                <>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                  <Grid item xs={11} sm={11} xl={11}>
                    <Controller
                      name='supervisorFollowUp'
                      {...register('supervisorFollowUp')}
                      control={control}
                      render={({ field }) => (
                        <TextareaAutosize
                          aria-label='minimum height'
                          minRows={3}
                          placeholder='enter supervisor follow up'
                          style={{
                            width: '100%',
                            borderRadius: '8px',
                            border: '2px solid #black',
                            height: '100px', // set a fixed height here
                            //   overflow: 'scroll'
                          }}
                          {...field}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
              )}
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Goals for Supervisee
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>


              {UserType && UserType == 'Therapist' ? (
                <>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                  <Grid item xs={11} sm={11} xl={11}>
                    <Controller
                      name='superviseeGoals'
                      {...register('superviseeGoals')}
                      control={control}
                      render={({ field }) => (
                        <TextareaAutosize
                          aria-label='minimum height'
                          readOnly={true}
                          minRows={3}
                          placeholder='enter goals for supervisee'
                          style={{
                            width: '100%',
                            borderRadius: '8px',
                            border: '2px solid #black',
                            height: '100px', // set a fixed height here
                            //   overflow: 'scroll'
                          }}
                          {...field}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
              ) : (
                <>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                  <Grid item xs={11} sm={11} xl={11}>
                    <Controller
                      name='superviseeGoals'
                      {...register('superviseeGoals')}
                      control={control}
                      render={({ field }) => (
                        <TextareaAutosize
                          aria-label='minimum height'
                          minRows={3}
                          placeholder='enter goals for supervisee'
                          style={{
                            width: '100%',
                            borderRadius: '8px',
                            border: '2px solid #black',
                            height: '100px', // set a fixed height here
                            //   overflow: 'scroll'
                          }}
                          {...field}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
              )}
            </>)}
          <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            <Controller
              name="superviseeSign"
              control={control}
              {...register("superviseeSign")}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Supervisee Signature..."
                  {...field}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSignInSupervisee}
                          disabled={UserType == 'Supervisor'} // Disable button for Therapist
                        >
                          <SignInIcon style={{ color: '#356F60' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  onChange={e => {
                    setValue('superviseeSign', e.target.value);
                  }}

                />
              }
            />
            {therapistSignError && <p style={{ color: 'red' }}> Supervisee Sign required</p>}
          </Grid>

          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            <Controller
              name="supervisorSign"
              control={control}
              {...register("supervisorSign")}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Supervisor Signature..."
                  {...field}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSignInSupervisor}
                          disabled={UserType == 'Therapist'} // Disable button for Therapist
                        >
                          <SignInIcon style={{ color: '#356F60' }} />

                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                  onChange={e => {
                    setValue('supervisorSign', e.target.value);
                  }}

                />
              }
            />
            {SupervisorSignError && <p style={{ color: 'red' }}> Supervisor Sign required</p>}

          </Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

          {UserType == 'Therapist' ? (<>
            {approve == true ? (
              <>
                <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5} style={{ marginTop: '10px', marginBottom: '10px' }} >
                  <Button
                    className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}

                    fullWidth
                    style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                    type="submit"
                  >
                    Approved
                    <TaskAltIcon
                      style={{
                        color: 'green'
                      }}
                    />
                  </Button>
                </Grid>

              </>) : (<>
                <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                <Grid item xs={5.5} sm={5.5} xl={5.5} style={{ marginTop: '10px', marginBottom: '10px' }} >
                  <Button
                    className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={approve ? true : submitting}
                    fullWidth
                    style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                    type="submit"
                  >
                    {/* Send For Approved */}
                    {Incidentid > 0 ? "Send For Approval" : "Save"}
                    <TaskAltIcon style={{
                      color: 'red',
                      display: Incidentid > 0 ? 'block' : 'none'
                    }}
                    />

                  </Button>
                </Grid>

              </>)}


          </>) : (<>
            <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

            <Grid item xs={5.5} sm={5.5} xl={5.5} style={{ marginTop: '10px', marginBottom: '10px' }} >
              <Button
                className="Button"
                onClick={handleSubmit(onSubmit)}
                disabled={approve ? true : submitting}
                fullWidth
                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                type="submit"
              >
                {Incidentid > 0 ? "Approve" : "Save"}
                <TaskAltIcon
                  style={{
                    color: approve == true ? 'green' : 'red',

                  }}
                />

              </Button>
            </Grid>

          </>)}

          {/* <Grid item xs={6} sm={6} xl={6} style={{marginTop:'10px'}}>
                <Button
          className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
                  type="submit"
                 >
                   Save
                   </Button>
                </Grid> */}


          <Grid item xs={5.5} sm={5.5} xl={5.5} style={{ marginTop: '10px' }}>
            <Button
              className="Button"
              onClick={props.handleClose}
              disabled={submitting}
              fullWidth
              style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}

            >

              Cancel
            </Button>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

        </Grid>



        {/* </Box> */}
      </Grid>
    </React.Fragment>
  );
};
export default IncidentRegistration;
