import { useState, useEffect,useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';
import Documents from "./Documents";
import EducationRegistration from "./EducationRegistration";
import Education from "./Education";
import Card from '@mui/material/Card';
import { ToastContainer, toast } from 'react-toastify';


import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select,
  Dialog, DialogActions, DialogContent, DialogContentText, 
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from '@mui/material/RadioGroup'
import GrowthPlanRegistration from "./GrowthPlanRegistration";
import TherapistRegistration from "./TherapistRegistration";



const SupervisorRegistration = props => {
  const fields = [
    "city",
    "contactNo",
    "userName",
    "createdBy",
    "createdDate",
    "dateJoined",
    "defaultFacilityId",
    "defaultPracticeId",
    "dob",
    "email",
    "fullName",
    "userName",
    "gender",
    "id",
    "provider_id",
    "isActive",
    "isStaff",
    "isSuperUser",
    "isVerified",
    "lastLogin",
    "password",
    "profilePhoto",
    "state",
    "street",
    "token",
    "updatedBy",
    "updatedDate",
    "userType",
    "zipCode",
    "provider",
    "address",
    "PracticeId",
    "photoUrl",
    "userslinked",
    "linkedUsersId",
    "resetPassword",
    "age",
    "ethnicity",
    "race",
    "licenseType",
    "licenseNumber",
    "licenseApprovalDate",
    "licenseExpirationDate",
    "dateIDIssued",
    "dateIDExpiration",
    "preferredGender",
    "insuranceCarrierName",
    "policyNumber",
    "insurancePolicyDate",
    "insuranceEndDate",
    "rcName",
    "rcRelationship",
    "rccontactNo",
    "rcEmail",
    "rcAddress",
  ];
  const defValues = {
    city: "",
    userName:"",
    contactNo: "",
    createdBy: "",
    userName:"",
    createdDate: "",
    dateJoined: "",
    defaultFacilityId: "",
    defaultPracticeId: "",
    dob: "",
    userslinked: "",
    email: "",
    fullName: "",
    gender: "",
    id: 0,
    provider_id: "",
    isActive: "",
    isStaff: "",
    isSuperUser: "",
    isVerified: "",
    lastLogin: "",
    password: "",
    profilePhoto: "",
    state: "",
    street: "",
    token: "",
    updatedBy: "",
    updatedDate: "",
    userType: "",
    zipCode: "",
    provider: "",
    address: "",
    PracticeId: "",
    photoUrl: "",
    linkedUsersId: "",
    resetPassword: "",
    age: "",
    ethnicity: "",
    race: "",
    licenseType: "",
    licenseNumber: "",
    licenseApprovalDate: "",
    licenseExpirationDate: "",
    dateIDIssued: "",
    dateIDExpiration: "",
    preferredGender:"",
    insuranceCarrierName:"",
    policyNumber:"",
    insurancePolicyDate:"",
    insuranceEndDate:"",
    rcName:"",
    rcRelationship:"",
    rccontactNo:"",
    rcEmail:"",
    rcAddress:"",
   
  };
  const [therapist, setTherapist] = React.useState("");
  console.log('viewdocu',therapist)
 
  const [therapistmodalreg, settherapistmodalreg] = React.useState(false);
  const handleopentherapistregmodal= () => {
    
    settherapistmodalreg(true)
  };
  const handleclosetherapistregmodal = () => {
    settherapistmodalreg(false)
  };

  const [therapistmodal, settherapistmodal] = React.useState(false);
  const handleopentherapistmodal= () => {
    
    settherapistmodal(true)
  };
  
  const handleclosetherapistmodal = () => {
    settherapistmodal(false)
  };
  const columnstherapist = [
   
    {
      field: 'therapistId',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
    {
      field: 'groupName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Group Name',
      flex: 0.3
    },
     
    
    {
      // field: 'userType',
      headerClassName: 'super-app-theme--header',
      headerName: 'View',
      flex: 0.1,
      renderCell: cellValues => (
        <RemoveRedEyeIcon
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            setTherapist(cellValues.row.therapistId)
            settherapistmodal(true)
            // openUserRegistration(event,cellValues.row.id )
          }}
        >{`${cellValues.row.userName}`}</RemoveRedEyeIcon>
      )
    },
    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                     
                      inActive: true,
                      id: cell.row.id
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapist`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
  ]
  const modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #EA7772',
    boxShadow: 24,
    overflow: 'hidden',
    p: 2,
    borderRadius: '8px',
    overflow:'auto',
  };
  const modalstyle1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    width:'700px',
    border: '2px solid #EA7772',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
    borderRadius: '8px',
    overflow:'auto',
  };
  const [groupName, setGroupName] = React.useState(false);
  const handleOpengroupName = () => setGroupName(true);
  const handleClosegroupName = () => setGroupName(false);
  const [Emergency, setEmergency] = React.useState(false);
  const handleOpenEmergency = () => setEmergency(true);
  const handleCloseEmergency = () => setEmergency(false);
  // const [isLoading, setisLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  function applyRefresh() {
    setRefresh(!refresh)
  }
  const [userName, setuserName] = useState("");
  console.log('userName',userName)
  const [userType, setuserType] = useState("");
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [errorReceived, seterrorReceived] = useState("");
  const [zipDownloaded, setzipDownloaded] = useState(false);
  const [fetchedData, setfetchedData] = useState(false);
  const [userPractice, setUserPractice] = useState(0);
  const [userFacility, setUserFacility] = useState(0);
  const [rows, setRows] = useState(null);
  const [rows1, setRows1] = useState(null);
  const [Dob, setDob] = React.useState("")
  const [licenseApprovalDate, setlicenseApprovalDate] = React.useState("")
  const [licenseExpirationDate, setlicenseExpirationDate] = React.useState("")
  const [dateidissued, setDateidissued] = React.useState("")
  const [dateIDExpiration, setDateIDExpiration] = React.useState("")
  const [insurancePolicyDate, setInsurancePolicyDate] = React.useState("")
  const [insuranceEndDate, setInsuranceEndDate] = React.useState("")
  
  const [providerType, setProviderType] = React.useState("");
  const [userChildList, setUsersChildList] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [selectedUserIds, setselectedUserIds] = React.useState([]);
  const [selectUserError, setselectUserError] = useState(false);
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [cellPhone, setcellPhone] = React.useState()
  const [rcellPhone, setrcellPhone] = React.useState()

  

  const location = useLocation();
  const [_userId, set_userId] = useState( props.callingFrom == 'dashboard' ? props.uid:  props.uid)

  // const _userId = {props.callingFrom == 'dashboard'?props.uid: location.state.uid};
  // const PracticeId = location.state.PracticeId;

  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  // const Emailaddress = localStorage.getItem("Emailaddress")
  const [Emailaddress, setEmailaddress] = useState("");
  const [practicesList, setpracticesList] = useState("");
  console.log("practicesList", practicesList);
  const [practiceslistid, setpracticeslistid] = useState([]);
//   const practiceslistid = [];
  console.log("practiceslistid", practiceslistid);
  const facilitiesList = [];
 
  const [practiceID, setPracticeID] = useState("");
  console.log("aaaa", practiceID);
  const [userProvider, setUserProvider] = useState(0);
  const [providers, setProviders] = useState([]);
  const [defaultPracticeError, setDefaultPracticeError] = useState(false);
  const [defaultPracticeErrorId, setDefaultPracticeErrorId] = useState("");
  const validationSchema = Yup.object({
    // fullName: Yup.string().required("Full Name is required"),
    
    userType: Yup.string().required("User Type is required"),

    userName: Yup.string().required("User Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(3, "Password must be at least 3 characters"),

    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords does not match"
    ),
    age: Yup.string()
    .required("Age is required")
    .matches(/^\d+$/, "Age must contain only digits"),
    // defaultPracticeId: Yup.array().required("Practice Name is required")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
  
  const [Emergencycontantid, setEmergencycontantid] = useState("");

  const [Emergencycontant, setEmergencycontant] = useState([]);
  console.log("testconat",Emergencycontant)
  
  const [groupname, setgroupname] = useState("");
  const [groupcomment, setgroupcomment] = useState("");

  

  const [Namecontant, setNamecontant] = useState("");
  const [Relationshipcontant, setRelationshipcontant] = useState("");
  const [Phonecontant, setPhonecontant] = useState("");
  const [Emailcontant, setEmailcontant] = useState("");
  const [Addresscontant, setAddresscontant] = useState("");
  const [EmergencyErorr, setEEmergencyErorr] = useState(false);

  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  // const onchangeEmergency = () => {
  //   setEmergencycontant(prevEmergencycontant => [...prevEmergencycontant, { Namecontant }]);
  // }
  // const [error, setError] = useState('');
  const [nameError, setNameError] = useState('');
const [relationshipError, setRelationshipError] = useState('');
const [phoneError, setPhoneError] = useState('');
const [addressError, setAddressError] = useState('');

  const onchangeEmergency = async () => {
    let isValid = true;
  
    if (!Namecontant.trim()) {
      setNameError('Full Name Required');
      isValid = false;
    } else {
      setNameError('');
    }
  
    if (!Relationshipcontant.trim()) {
      setRelationshipError('Relationship Required');
      isValid = false;
    } else {
      setRelationshipError('');
    }
  
    if (!Phonecontant.trim()) {
      setPhoneError('Phone Number Required');
      isValid = false;
    } else {
      setPhoneError('');
    }
  
    if (!Addresscontant.trim()) {
      setAddressError('Address Required');
      isValid = false;
    } else {
      setAddressError('');
    }
  
    if (!isValid) return;
  
    Emergencycontant.push({
      "id": 0,
      "userId": _userId,
      "fullname": Namecontant,
      "relationship": Relationshipcontant,
      "phone": Phonecontant,
      "email": Emailcontant, // You can leave the email as is
      "address": Addresscontant,
    });
    setEEmergencyErorr(false)
    handleCloseEmergency();
  };
  
  
  const onSubmit = data => {
    var selectedIds = [];
    var unSelectedIds = [];
    selectedUserIds.forEach(field => {
      if (typeof field.value != undefined && field.value != null)
        selectedIds.push(field.value);
      else selectedIds.push(field.id);
    });
    let postData = data;
    console.log("checck selct postData___", postData);
    postData.linkedUsersId = selectedIds;
    postData.emergencyContact = Emergencycontant;
    console.log("checck selct id________", postData.linkedUsersId);
    postData.unlinkedUsersId = [];
    postData.userType = userType ? userType : "";
    if (isPasswordChange) {
      console.log('ispassword change  my aaya ha k nahiii ')
      postData.password = md5(postData.password);
      postData.confirmPassword = md5(postData.confirmPassword);
    } else {
      postData.password = postData.password;
      postData.confirmPassword = postData.confirmPassword;
    }
    if (postData.userType == "" || postData.userType == null) {
      setselectUserError(true);
      return;
    }
    if (!Emergencycontant || Emergencycontant.length === 0) {
      setEEmergencyErorr(true)
      return;
    } 

    setSubmitting(true);
    seterrorReceived("");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Users/userRegistration`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          toast.success('Record saved successfully', { 
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        
            onClose: () => {
              if(props.callingFrom == 'dashboard')
              {
                props.handleCloseprofile()
                props.setRefresh(!props.refresh)
              }
              else{
              props.handleClose()
              props.setRefresh(!props.refresh)

              }
            }

            });
          // alert.success("Record saved successfully.", {
          //   type: "success",
          //   onClose: () => {
          //     if(props.callingFrom == 'dashboard')
          //     {
          //       props.handleCloseprofile()
          //     }
          //     else{
          //       navigate("/Supervisor");
          //     }
           
          //   }
          // });
        } else if (response.length > 0) {
          seterrorReceived(response.data);
          setSubmitting(false);
          return;
        }
        
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const [userEmail, setUserEmail] = useState("");

  const [value, setvalue] = React.useState(0);
  const handleChangetab = (event, newValue) => {
    setvalue(newValue);
  };
  const uId = localStorage.getItem('userid')

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUserById?id=${_userId}`, {
        headers
      })
      .then(response => {
        console.log("response.data: ", response);
        setuserType(response["userType"]);
        setcellPhone(response.contactNo)
        setrcellPhone(response.rccontactNo)

        if(response.emergencyContacts != null ){
          setEmergencycontant(response.emergencyContacts)

        }
        // setEmergencycontantid(response.id)
        fields.forEach(field => {
          setValue(field, response[field]);
          var child = [];
          response.userslinked.forEach((res) => {
            child.push({
              name: res.fullName,
              value: res.id,
            });
          });
          setUsersChildList(child);
          setselectedUserIds(child);
          setProviderType(response.userType);
          setProviderType(response.userType);
          var dateYMD = Moment(response.dob).format('YYYY-MM-DD')
          setDob(dateYMD)
          var dateYMD = Moment(response.licenseApprovalDate).format('YYYY-MM-DD')
          setlicenseApprovalDate(dateYMD)
          var dateYMD = Moment(response.dateIDIssued).format('YYYY-MM-DD')
          setDateidissued(dateYMD)
          var dateYMD = Moment(response.dateIDExpiration).format('YYYY-MM-DD')
          setDateIDExpiration(dateYMD)
          var dateYMD = Moment(response.insurancePolicyDate).format('YYYY-MM-DD')
          setInsurancePolicyDate(dateYMD)
          var dateYMD = Moment(response.insuranceEndDate).format('YYYY-MM-DD')
          setInsuranceEndDate(dateYMD)
          
          var dateYMD = Moment(response.licenseExpirationDate).format('YYYY-MM-DD')
          setlicenseExpirationDate(dateYMD)
          
          setEmailaddress(response.email)
          if (field === "userName") {
            setuserName(response[field]);
          }
          if (field === "password") {
            setValue("confirmPassword", response[field]);
          }
        });
        setPracticeID(response.defaultPracticeId);

        setDefaultPracticeErrorId(response.defaultPracticeId);
      })
      .catch(error => {});
  }, []);

 
  const [images, setImages] = React.useState(null);
  ///Delete Image
  const deleteimg = () => {
    setImages(null);
    setValue("photoUrl", "");
  };
  ///Upload Image
  const onImageChange = e => {
    setImages(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    convertBase64(file);
    // console.log("64 image",base64)
    console.log(
      "Kdr Sy aya ha Console",
      URL.createObjectURL(e.target.files[0])
    );
  };
  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    }).then(users => {
      setValue("base64", users);
    });
  };
  const Input = styled("input")({
    display: "none"
  });

  const [open, setOpen] = React.useState(false);

  function handleCancel() {
    navigate("/Supervisor");
  }
  function handleTherapist() {
    navigate("/Therapist");
  }
  const [tabValue, settabValue] = React.useState(0);
  // const handleChange = (event, newValue) => {
  //   settabValue(newValue);
  //   setValue("defaultPracticeId", "");
  //   setValue("provider_id", "");
  //   setPracticeID("");
  //   setProviderIdError(false);
  //   setPracticeIDError(false);
  //   setDefaultPracticeError(false);
  // };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #2E6443',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow:'auto',
    /* WebKit (Chrome, Safari) */
'&::-webkit-scrollbar': {
width: 8, /* Width of vertical scrollbar */
height: 10,
},
'&::-webkit-scrollbar-thumb': {
backgroundColor: '#2E6443',
borderRadius: 10,
},
'&::-webkit-scrollbar-track': {
backgroundColor: 'transparent',
},
/* Adjust the width for the horizontal scrollbar */
'&::-webkit-scrollbar-thumb:horizontal': {
width: 4, /* Width of horizontal scrollbar */
},
/* For Firefox */
'&*': {
scrollbarWidth: 'thin',
},
  }
  const [docError, setdocError] = useState(false)
  const [filename, setfilename] = useState([])
  const [base64Data, setBase64Data] = useState([])
  const [totalProgress, setTotalProgress] = React.useState(0)
  const [savedFiles, setSavedFiles] = React.useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const inputRef = useRef(null)
  // document Uplaod
  const fileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  ///////////////////////////////////
  //Single and multiple files
  const handleChangeforfile = async files => {
    const newFilenames = []
    const newBase64Data = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const base64 = await fileToBase64(file)
      newFilenames.push(file.name)
      newBase64Data.push(base64)
    }

    setfilename(newFilenames)
    setBase64Data(newBase64Data)
    setdocError(false)
  }
  const [TherapistID, setTherapistID] = useState("");
  const [groupID, setGroupID] = useState("");

  console.log('TherapistID',TherapistID)
  const [TherapistList, setTherapistList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
        .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/GetUsersBySupervisorId?supervisorId=${uId}&userType=${'Therapist'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setTherapistList(prac);
        
      })
      .catch(error => {});

  }, []);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/SupervisorGroups/supervisorGroupList?supervisorId=${_userId}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.groupName,
            value: field.id
          });
        });
        setGroupList(prac);
        
      })
      .catch(error => {});

  }, [refresh]);
 
  const [therapistsIdError, setTherapistsIdError] = React.useState(false);
  const [GroupIdError, setGroupIdError] = React.useState(false);


  const AddTherapist = (event) => {
    if (!TherapistID) {
      setTherapistsIdError(true);
      setSubmitting(false);
      return;

    } 
    if (!groupID) {
      setGroupIdError(true);
      setSubmitting(false);
      return;

    } 
   
   
    const postData = {
      therapistId: TherapistID,
      supervisorId : _userId,
      groupId: groupID,
      type : "Therapist"
    };
   
    axios
    .post(
      `${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapist`,
      postData,
      { headers }
    )
    .then(response => {
      alert.success("Record saved successfully.", {
        type: "success",
        onClose: () => {
          applyRefresh()
          // navigate("/Supervisor");
        }
      });
    })
    .catch(error => {
      setSubmitting(false);
      alert.error(`Error ${error.message}`, {
        type: "error"
      });
    });

  }

  const AddGroup = (event) => {
    console.log('data',event)
   
   
    const postData = {
      groupName: groupname,
      comments : groupcomment,
      supervisorId: _userId,
     
    };
   
    axios
    .post(
      `${process.env.REACT_APP_API_URL}/SupervisorGroups/addSupervisorGroup`,
      postData,
      { headers }
    )
    .then(response => {
      alert.success("Record saved successfully.", {
        type: "success",
        onClose: () => {
          applyRefresh()
          handleClosegroupName()
        }
      });
    })
    .catch(error => {
      setSubmitting(false);
      alert.error(`Error ${error.message}`, {
        type: "error"
      });
    });

  }
  const [isLoading, setLoading] = useState(false)
  const [rowstherapist, setRowstherapist] = useState(null)
  const [rowstherapistgroup, setRowstherapistgroup] = useState(null)

  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapistList?supervisorId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowstherapist(response)
        setRowstherapistgroup(response)
      })
      .catch(error => {})

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
 
  const checkstyle1 = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #EA7772',
    boxShadow: 24,
    // overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
   
   
  }
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);

  const handleOpenModal = (row) => {
    setDeleteRow(row);
    setDeleteAlert(true);
  };

  const handleCloseModal = () => {
    setDeleteAlert(false);
    setDeleteRow(null);
  };

  const handleDelete = () => {
    const postData = {
      inActive: true,
      id: deleteRow.id
    };

    axios.post(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapist`, postData, { headers })
      .then(() => {
        setRefresh(!refresh);
        handleCloseModal();
      })
      .catch(error => {
        console.error('There was an error!', error);
        handleCloseModal();
      });
  };
  return (
    <React.Fragment>
       <Grid container>
       
       <Modal
        open={groupName}
        onClose={handleClosegroupName}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle}>
          <Grid container spacing={1}>
        
         
          <Grid item xs={12} sm={12} xl={12} > 
        <Headroom >
                
                  <Grid
            container xs={12}
            style={{
              background: '#377562',
              position: 'sticky',
              marginBottom: '14px',
              padding: '8px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(54, 118, 196, 0.5)',
              display: 'flex'
            }}
          >
            <Grid item xs={11}>
              <Typography style={{
                fontSize: '17px',
                fontWeight: 'bold',
                marginLeft: '5px',
                color: '#fff'
              }}> Add Groups </Typography>
            </Grid>
            <Grid item xs={1}>
              <CancelOutlinedIcon
                onClick={handleClosegroupName}
                fontSize="medium"
                color="red"
                style={{
                  color: 'red',
                  cursor: 'pointer',
                  float: 'right',
                }}
              ></CancelOutlinedIcon>
            </Grid>
          </Grid>
              </Headroom>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
          <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px',marginTop:'5px' }}>
                     Group Name
                  </Typography>
                </Grid>
                <Grid item xs={9} sm={9} xl={9}>
                <Controller
                        name="FullName"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please Enter Group Name"
                            {...field}
                            fullWidth
                            value={groupname}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setgroupname(ev.target.value)
                              
                              }
                            }
                           
                          />}
                      />
                
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px' }}>
                 Comment
                  </Typography>
                </Grid>
              {/* <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid> */}
               
                <Grid item xs={9} sm={9} xl={9}>
                <Controller
                        name="groupcomment"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please Enter  Comment..."
                            {...field}
                            fullWidth
                            multiline
                            rows={2}
                            value={groupcomment}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                              setgroupcomment(ev.target.value)
                              
                              }
                            }
                            
                          />}
                      />
                
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={8} sm={8} xl={8} style={{marginTop:'10px'}}></Grid>
                <Grid item xs={3} sm={3} xl={3} style={{marginTop:'10px'}}>
                <Button
          className="Button"
                    onClick={AddGroup}
                    // disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold',float:'right',marginBottom:'10px'}}
                  type="submit"
                 >
                   Save
                   </Button>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

          </Grid>
         
        </Box>
      </Modal>
       <Modal
        open={Emergency}
        onClose={handleCloseEmergency}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle}>
          <Grid container spacing={1} >
          <Grid container spacing={1} style={{background:'#356F60',display:'flex',borderRadius:'8px',padding:'3px'}}>
          <Grid item xs={11} sm={11} xl={11} >
          <Typography
                        style={{
                          fontWeight: 'bold',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize:'1rem'
                        }}
                      >
                           Emergency Contact
                      </Typography>
          </Grid>
                  <Grid item xs={1} sm={1} >
                      <CancelOutlinedIcon
                        onClick={handleCloseEmergency}
                        color="red"
                        style={{
                          color: 'red',
                          // paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right',
                          fontSize:'28px'
                   
                        }}
                      ></CancelOutlinedIcon>
                  </Grid>
          </Grid>
         
          <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                     Full Name
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                 Relationship
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="FullName"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter full name"
                            {...field}
                            fullWidth
                            value={Namecontant}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setNamecontant(ev.target.value)
                             setNameError('');
                              }
                            }
                           
                          />}
                      />
                          {nameError && (
                <Typography style={{ color: 'red', fontSize: '12px' }}>
                  {nameError}
                </Typography>
              )}
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="Relationship"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter relationship"
                            {...field}
                            fullWidth
                            value={Relationshipcontant}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setRelationshipcontant(ev.target.value)
                             setRelationshipError('');

                              
                              }
                            }
                            
                          />}
                      />
                  {relationshipError && (
              <Typography style={{ color: 'red', fontSize: '12px' }}>
                {relationshipError}
              </Typography>
            )}
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Phone
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Email
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <InputMask
                mask='+1 999-999-9999'
                disabled={false}
                value={Phonecontant}
                style={{ height: '30px' }}
                onChange={ev => {
                  console.log('ee:', ev.target.value)
                 setPhonecontant(ev.target.value)
                 setPhoneError('')
                  
                  }
                }
              >
                {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='enter cell phone' />}
              </InputMask>
                {/* <Controller
                        name="Phone"
                       
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter Phone No"
                            {...field}
                            fullWidth
                            value={Phonecontant}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setPhonecontant(ev.target.value)
                              
                              }
                            }
                            
                          />}
                      /> */}
              {phoneError && (
              <Typography style={{ color: 'red', fontSize: '12px' }}>
                {phoneError}
              </Typography>
            )}
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="Email"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                          type="email"
                            size="small"
                            placeholder="please enter email"
                            {...field}
                            fullWidth
                            value={Emailcontant}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                              setEmailcontant(ev.target.value)
                              
                              }
                            }
                            
                          />}
                      />
               
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                <Typography style={{ fontSize: '14px' }}>
                 Address
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                <Controller
                        name="Address"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="please enter address"
                            {...field}
                            fullWidth
                            value={Addresscontant}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                              setAddresscontant(ev.target.value)
                              setAddressError('')
                              
                              }
                            }
                            
                          />}
                      />
                {addressError && (
              <Typography style={{ color: 'red', fontSize: '12px' }}>
                {addressError}
              </Typography>
            )}
                </Grid>
                <Grid item xs={12} sm={12} xl={12} style={{marginTop:'10px'}}>
                <Button
          className="Button"
                    onClick={onchangeEmergency}
                    // disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold',float:'right',marginBottom:'10px'}}
                  type="submit"
                 >
                   Save
                   </Button>
                </Grid>
          </Grid>
         
        </Box>
      </Modal>
      <Modal
          open={therapistmodal}
          onClose={handleclosetherapistmodal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={modalstyle1}>
           <Grid container spacing={1}>
           <Grid item xs={10} sm={10} xl={10} style={{marginTop:"-20px"}}>
          <Typography style={{ fontSize: '18px',fontWeight:'bold', color:'black'}}>
                      Therapist Document
                  </Typography>
          </Grid>
          <Grid item xs={2} sm={2} xl={2} style={{marginTop:"-25px"}}>
          <CancelOutlinedIcon
                        onClick={handleclosetherapistmodal}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
          </Grid>
           </Grid>
          <Documents patientid={therapist} type={'Therapist'} ></Documents>


          </Box>
        </Modal>
        <Modal
          open={therapistmodalreg}
          onClose={handleclosetherapistregmodal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box  sx={checkstyle1}  >
          <Headroom >
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            // textAlign: 'left',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Therapist Registration

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                          onClick={handleclosetherapistregmodal}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{ overflow: 'auto',
             /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
            
            
            height: '80vh' }} > 
             <TherapistRegistration callingFrom = {'TherapistRegistration'} uid={therapist}></TherapistRegistration>
             
              </Card>
              <ToastContainer
             position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
             
              />
            </Grid>


          </Box>
        </Modal>
        {/* <Box sx={style}> */}
       
        <Grid container spacing={1}>
         
       
                <Grid item xs={12} sm={12} >
          <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangetab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="lab API tabs example"
              >
                <Tab value={0} label="Supervisor" />
                <Tab value={1} label="Therapists" />
                <Tab value={3} label="Continuing Education" />
                <Tab value={4} label="Growth Plan" />
                <Tab value={2} label="Document" />
              
                

                

              </TabList>
            </Box>
            <TabPanel value={0}>
             <Grid container spacing={1}>
             <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                     Name
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Email
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="userName"
                        {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="please enter full name"
                            {...field}
                            fullWidth
                            error={errors.userName}
                          />}
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.userName?.message}</p>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="email"
                        {...register("email")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="please enter email"
                            {...field}
                            fullWidth
                            error={errors.email}
                          />}
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.email?.message}</p>
                </Grid>

                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Password
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Confirm Password
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="password"
                        {...register("password")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="please enter password"
                            value={field.value}
                            type="password"
                            onChange={(e) => {
                              setValue("password", e.target.value);
                            }}
                            fullWidth
                            error={errors.password}
                          />}
                      />
                     
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.password?.message}</p>
                </Grid>
              
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="confirmPassword"
                        {...register("confirmPassword")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="please enter confirm password"
                            {...field}
                            fullWidth
                            type="password"
                            onChange={(e) => {
                              setIsPasswordChange(true);
                              setValue("confirmPassword", e.target.value);
                              console.log("setIsPasswordChange my kya value ha ", isPasswordChange)
                            }}
                            error={errors.confirmPassword}
                          />
                        }
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.confirmPassword?.message}</p>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                     DOB
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                     Age
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="dob"
                        // {...register("dob")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='date'
                        size='small'
                        fullWidth
                        {...field}
                        placeholder='enter dob'
                        value={Dob ? Dob : ''}
                        onChange={e => {
                          setValue('dob', e.target.value)
                          setDob(e.target.value)
                        }}
                        
                      />
                        }
                      />
               
                </Grid>
              
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="age"
                        {...register("age")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='text'
                        size='small'
                        fullWidth
                        {...field}
                        placeholder='enter age'
                        error={errors.age}

                      />
                        }
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.age?.message}</p>

               
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                     Gender
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Preferred Gender
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                name='gender'
                margin='dense'
                {...register('gender')}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    style={{ marginTop: 0, marginBottom: 0 }}

                    {...field}
                    options={[
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                      { value: 'Other', label: 'Other' }
                    ]}
                    value={{ label: getValues('gender') }}
                    onChange={value => setValue('gender', value.label)}
                    size='small'
                  />
                )}
              />
               
                </Grid>
              
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                name='preferredGender'
                margin='dense'
                {...register('preferredGender')}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    style={{ marginTop: 0, marginBottom: 0 }}

                    {...field}
                    options={[
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                      { value: 'NB', label: 'NB' }
                    ]}
                    value={{ label: getValues('preferredGender') }}
                    onChange={value => setValue('preferredGender', value.label)}
                    size='small'
                  />
                )}
              />
               
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Ethnicity
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                 Race
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="ethnicity"
                        {...register("ethnicity")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='text'
                        size='small'
                        fullWidth
                        {...field}
                        placeholder='enter ethni city'
                      />
                        }
                      />
               
                </Grid>
               
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="race"
                        {...register("race")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='text'
                        size='small'
                        fullWidth
                        {...field}
                        placeholder='enter race'
                      />
                        }
                      />
               
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Address
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Phone No
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="address"
                        {...register("address")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='text'
                        size='small'
                        fullWidth
                        {...field}
                        placeholder='Enter address'
                      />
                        }
                      />
               
                </Grid>
                
                <Grid item xs={6} sm={6} xl={6}>
                <InputMask
                mask='+1 999-999-9999'
                disabled={false}
                value={cellPhone}
                style={{ height: '30px' }}
                onChange={e => {
                  setValue('contactNo', e.target.value)
                  setcellPhone(e.target.value)
                }}
              >
                {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter cell phone' />}
              </InputMask>
               
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  License Type
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  License Number
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="licenseType"
                        {...register("licenseType")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='text'
                        size='small'
                        fullWidth
                        {...field}
                        placeholder='Enter license type'
                      />
                        }
                      />
               
                </Grid>
               
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="licenseNumber"
                        {...register("licenseNumber")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='text'
                        size='small'
                        fullWidth
                        {...field}
                        placeholder='Enter license number'
                      />
                        }
                      />
               
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                License Approval Date
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                License Expiration  Date
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="licenseApprovalDate"
                        // {...register("dob")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='date'
                        size='small'
                        fullWidth
                        {...field}
                        value={licenseApprovalDate ? licenseApprovalDate : ''}
                        onChange={e => {
                          setValue('licenseApprovalDate', e.target.value)
                          setlicenseApprovalDate(e.target.value)
                        }}
                        
                      />
                        }
                      />
               
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="licenseExpirationDate"
                        // {...register("dob")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='date'
                        size='small'
                        fullWidth
                        {...field}
                        value={licenseExpirationDate ? licenseExpirationDate : ''}
                        onChange={e => {
                          setValue('licenseExpirationDate', e.target.value)
                          setlicenseExpirationDate(e.target.value)
                        }}
                        
                      />
                        }
                      />
               
                </Grid>

                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Date ID Issued
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Date ID Expiration
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="dateIDIssued"
                        // {...register("dob")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='date'
                        size='small'
                        fullWidth
                        {...field}
                        value={dateidissued ? dateidissued : ''}
                        onChange={e => {
                          setValue('dateIDIssued', e.target.value)
                          setDateidissued(e.target.value)
                        }}
                        
                      />
                        }
                      />
               
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="dateIDExpiration"
                        // {...register("dob")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='date'
                        size='small'
                        fullWidth
                        {...field}
                        value={dateIDExpiration ? dateIDExpiration : ''}
                        onChange={e => {
                          setValue('dateIDExpiration', e.target.value)
                          setDateIDExpiration(e.target.value)
                        }}
                        
                      />
                        }
                      />
               
                </Grid>
               
                <Grid item xs={12} sm={12} xl={4}>
                <Typography style={{ fontSize: '14px' }}>
                Type
                  </Typography>
                </Grid>
               
                <Grid item xs={12} sm={12} xl={4}>
                <Controller
                    name="userType"
                    margin="dense"
                    {...register("userType")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        style={{ marginTop: 0, marginBottom: 0 }}
                        {...field}
                        size="small"
                        options={[
                          { value: "Admin", label: "Admin" },
                          { value: "Supervisor", label: "Supervisor" },
                          { value: "Therapist", label: "Therapist" },
                        ]}
                        value={{ label: getValues("userType") }}
                        onChange={value => {
                          setValue("userType", value.value);
                          setuserType(value.value);
                          setProviderType(value.label);
                        }}
                        error={errors.userType}

                      />}
                  />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.userType?.message}</p>

                </Grid>

                <Grid item xs={10} sm={10} xl={10} style={{marginTop:'5px'}}>
                <Typography style={{ fontSize: '14px',fontWeight:'bold',color:'black' }}>
                 Emergency Contacts
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                <AddCircleOutlineRoundedIcon
                        onClick={handleOpenEmergency}
                        fontSize="large"
                        color="green"
                        style={{
                          color: 'green',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></AddCircleOutlineRoundedIcon>
                {/* <Button onClick={handleOpenEmergency}>Open modal</Button> */}
                </Grid>

                {EmergencyErorr && (
                  <Typography style={{ color: 'red', fontSize: '12px',marginLeft:'5px'}}>
                   Please Select Emergency Contacts
                  </Typography>
                )}
                <Grid item xs={12} sm={12} xl={12}>
                {Emergencycontant != null && Emergencycontant.length > 0
                  ? Emergencycontant.map((row, index) => {
                    return(
                      <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '5px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.9rem',
                        marginBottom: '1rem',
                        marginLeft: '0 .2rem'
                      }}
                    >
                     <Grid container spacing={1}>
                     <Grid item xs={6} sm={6} xl={6}>
                      <Typography style={{ fontSize: '14px' }}>
                       <b>Name:</b>  {row.fullname}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                      <Typography style={{ fontSize: '14px',color:'black' }}>
                      <b>Relationship:</b> {row.relationship}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                      <Typography style={{ fontSize: '14px' }}>
                       <b>Phone:</b>  {row.phone}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                      <Typography style={{ fontSize: '14px',color:'black' }}>
                      <b>Email:</b> {row.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} xl={12}>
                      <Typography style={{ fontSize: '14px',color:'black' }}>
                      <b>Address:</b> {row.address}
                        </Typography>
                      </Grid>
                     </Grid>
                    </Paper>
                    )
                   
                  })
                  : 'No Record Found'}
                </Grid>

                <Grid item xs={12} sm={12} xl={12}>
                <Typography style={{ fontSize: '14px',fontWeight:'bold',color:'black' }}>
                Records Custodian
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '5px',
                        borderColor: '#377562',
                       
                        padding: '.9rem',
                        marginBottom: '1rem',
                        marginLeft: '0 .2rem'
                      }}
                    >
                      <Grid container spacing={1}>
                       <Grid item xs={2} sm={2} xl={2} style={{marginTop:'5px'}}>
                <Typography style={{ fontSize: '14px', }}>
                    Name:
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} xl={4}>
                <Controller
                        name="rcName"
                        {...register("rcName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter name"
                            {...field}
                            fullWidth
                            
                          />}
                      />
                </Grid>
                <Grid item xs={2} sm={2} xl={2} style={{marginTop:'5px'}}>
                <Typography style={{ fontSize: '14px', }}>
                Relationship
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} xl={4}>
                <Controller
                        name="rcRelationship"
                        {...register("rcRelationship")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter relationship"
                            {...field}
                            fullWidth
                            
                          />}
                      />
                </Grid>
                <Grid item xs={2} sm={2} xl={2} style={{marginTop:'5px'}}>
                <Typography style={{ fontSize: '14px', }}>
                Phone:
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} xl={4}>
                <InputMask
                mask='+1 999-999-9999'
                disabled={false}
                // value={cellPhone}
                style={{ height: '30px' }}
                value={rcellPhone}

                onChange={e => {
                  setValue('rccontactNo', e.target.value)
                  setrcellPhone(e.target.value)
                }}
               
              >
                {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter cell phone' />}
              </InputMask>
                </Grid>
                <Grid item xs={2} sm={2} xl={2} style={{marginTop:'5px'}}>
                <Typography style={{ fontSize: '14px', }}>
                Email:
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} xl={4}>
                <Controller
                        name="rcEmail"
                        {...register("rcEmail")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                          type="email"
                            size="small"
                            placeholder="Please enter email"
                            {...field}
                            fullWidth
                            
                          />}
                      />
                </Grid>
                <Grid item xs={2} sm={2} xl={2} style={{marginTop:'5px'}}>
                <Typography style={{ fontSize: '14px', }}>
                Address:
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} xl={4}>
                <Controller
                        name="rcAddress"
                        {...register("rcAddress")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                          type="email"
                            size="small"
                            placeholder="Please enter address"
                            {...field}
                            fullWidth
                            
                          />}
                      />
                </Grid>
                </Grid>
                    </Paper>
                    </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                <Typography style={{ fontSize: '14px',fontWeight:'bold',color:'black' }}>
                Professional Liability Insurance
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} xl={4} style={{marginTop:'5px'}}>
                <Typography style={{ fontSize: '14px', }}>
                Insurance Carrier Name:
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} xl={8}>
                <Controller
                        name="insuranceCarrierName"
                        {...register("insuranceCarrierName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter insurance carrier name"
                            {...field}
                            fullWidth
                            
                          />}
                      />
                </Grid>
                <Grid item xs={4} sm={4} xl={4} style={{marginTop:'5px'}}>
                <Typography style={{ fontSize: '14px', }}>
                Policy Number:
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} xl={8}>
                <Controller
                        name="policyNumber"
                        {...register("policyNumber")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter policy number"
                            {...field}
                            fullWidth
                            
                          />}
                      />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px', }}>
                Insurance Policy Date
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px', }}>
                Insurance End Date
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="insurancePolicyDate"
                        // {...register("dob")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='date'
                        size='small'
                        fullWidth
                        {...field}
                        value={insurancePolicyDate ? insurancePolicyDate : ''}
                        onChange={e => {
                          setValue('insurancePolicyDate', e.target.value)
                          setInsurancePolicyDate(e.target.value)
                        }}
                        
                      />
                        }
                      />
               
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Controller
                        name="insuranceEndDate"
                        // {...register("dob")}
                        control={control}
                        render={({ field }) =>
                        <TextField
                        type='date'
                        size='small'
                        fullWidth
                        {...field}
                        value={insuranceEndDate ? insuranceEndDate : ''}
                        onChange={e => {
                          setValue('insuranceEndDate', e.target.value)
                          setInsuranceEndDate(e.target.value)
                        }}
                        
                      />
                        }
                      />
               
                </Grid>

                <Grid item xs={6} sm={6} xl={6} style={{marginTop:'10px'}}>
                <Button
          className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
                  type="submit"
                 >
                   Save
                   </Button>
                </Grid>
                  <Grid item xs={6} sm={6} xl={6} style={{marginTop:'10px'}}>
          <Button
          className="Button"
          onClick={props.callingFrom == 'dashboard'?props.handleCloseprofile:props.handleClose}
              disabled={submitting}
              fullWidth
              style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}

            >
             
              Cancel
            </Button>
               </Grid>
             </Grid>
              
            </TabPanel>
            <TabPanel value={1}> 
               <Grid container spacing={1}>
               <Grid item xs={1} sm={1} xl={1}>
               <Typography style={{ fontSize: '14px',color:'black',marginTop:'10px' }}>
                     Group
                  </Typography>

                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                       <Controller
                    name="groupId"
                    // {...register("userId")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={groupList.filter(function(option) {
                          return (
                            option.value === getValues("groupId")
                          );
                        })}
                        onChange={e => {
                            setValue("groupId", e.value);
                            setGroupID(e.value);
                            
                           
                          }}
                        options={groupList}
                      />
                     
                    }
                  />
                      {GroupIdError && <p style={{ color: 'red', fontSize: '14px' }}>Please select a Group.</p>}
                </Grid>
                <Grid item xs={1} sm={1} xl={1}>
                <AddCircleOutlineRoundedIcon
                        onClick={handleOpengroupName}
                        fontSize="large"
                        color="green"
                        style={{
                          color: 'green',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></AddCircleOutlineRoundedIcon>
                {/* <Button onClick={handleOpenEmergency}>Open modal</Button> */}
                </Grid>
               <Grid item xs={1.5} sm={1.5} xl={1.5}>
               <Typography style={{ fontSize: '14px',color:'black',marginTop:'10px' }}>
                     Therapist:
                  </Typography>

                </Grid>
                <Grid item xs={3.5} sm={3.5} xl={3.5}>
                       <Controller
                    name="userId"
                    // {...register("userId")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={TherapistList.filter(function(option) {
                          return (
                            option.value === getValues("userId")
                          );
                        })}
                        onChange={e => {
                            setValue("userId", e.value);
                            setTherapistID(e.value);
                            
                           
                          }}
                        options={TherapistList}
                      />
                     
                    }
                  />
                 {therapistsIdError && <p style={{ color: 'red', fontSize: '14px' }}>Please select a Therapist.</p>}

                </Grid>
                <Grid item xs={2} sm={2} xl={2} >
                  <Button
                  className="Button"
                      onClick={AddTherapist}
                      // disabled={submitting}
                      fullWidth
                      style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}

                    >
                    
                      Add
                    </Button>
               </Grid>
          
               <Grid item xs={12} sm={12} xl={12}>
               {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                 

            {rowstherapistgroup != null && rowstherapistgroup.length > 0
                  ? rowstherapistgroup.map((grouprow, index) => {
                    if(index+1<rowstherapistgroup.length && grouprow.groupName==rowstherapistgroup[index+1].groupName)
                     return null;
                   var grropname =  rowstherapist.filter(rowstherapist => rowstherapist.groupName === grouprow.groupName)

                    return (
                      <Paper
                      key={index} 
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '5px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.9rem',
                        marginBottom: '1rem',
                        marginLeft: '0 .2rem'
                      }}
                    >
                      <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} xl={12}>
                            <Typography style={{ fontSize: '14px' }}>
                              <b>Group Name:</b> {grouprow.groupName}
                            </Typography>
                          </Grid>
                          {grropname != null && grropname.length > 0
                  ? grropname.map((row, index) => {
                    return (
                      <>
                      {/* <Grid container spacing={1}> */}
                      <Grid item xs={2} sm={2} xl={2}>
                            <Typography style={{ fontSize: '14px' }}>
                            <b>User Name:</b>
                            </Typography>
                          
                          </Grid>
                          <Grid item xs={8} sm={8} xl={8}>
                            <Typography style={{ fontSize: '14px',marginTop:'-7px' }}>
                              <Button
                               onClick={(event) => {
                                setTherapist(row.therapistId)
                                settherapistmodalreg(true)
                                // openUserRegistration(event,cellValues.row.id )
                              }}
                              
                              >
                            {row.userName}
                              </Button>
                           
                            </Typography>
                          </Grid>
                          <Grid item xs={1} sm={1} xl={1}>
                          <RemoveRedEyeIcon
                                size='small'
                                variant='text'
                                color='info'
                                onClick={(event) => {
                                  setTherapist(row.therapistId)
                                  settherapistmodal(true)
                                  // openUserRegistration(event,cellValues.row.id )
                                }}
                              ></RemoveRedEyeIcon>
                          </Grid>
                          <Grid item xs={1} sm={1} xl={1}>
                          {/* <DeleteIcon
                          style={{
                            marginRight: '5px',
                            float: 'right',
                            marginTop: '5px',
                            color: 'red',
                            cursor: 'pointer',
                            marginTop:'-1px'
                          }}
                      onClick={cellValues => {
                        confirmAlert({
                          message: 'Do you want to delete the record.',
                          closeOnEscape: true,
                          closeOnClickOutside: true,
                          overlayClassName: 'overlay-custom-class-name',
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                const postData = {
                                
                                  inActive: true,
                                  id: row.id
                                }

                                axios
                                  .post(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapist`, postData, {
                                    headers
                                  })
                                  .then(response => {
                                    setRefresh(!refresh)
                                    // setOpenNote(false)
                                    // console.log('response.data: ', response.data)
                                  })
                                  .catch(error => {
                                    console.error('There was an error!', error)
                                  })
                              }
                            },
                            {
                              label: 'No'
                            }
                          ]
                        })
                      }}
                    ></DeleteIcon> */}
                     <DeleteIcon
                                  style={{
                                    marginRight: '5px',
                                    float: 'right',
                                    marginTop: '-1px',
                                    color: 'red',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => handleOpenModal(row)}
                                />
                       
                          </Grid>
                         
                      </>
                    );
             
                  }): 'No Record Found'}
                      </Grid>
                      </Paper>
                    );
                  })
                  : 'No Record Found'}

                  {/* {rowstherapist === null || rowstherapist.length === 0 ? null : (
                    <DataGrid
                      rows={rowstherapist}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={columnstherapist}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )} */}
                </div>
                )}
            
               </Grid>

               </Grid>
               <Dialog
        open={deleteAlert}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete the record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
                </TabPanel>
                <TabPanel value={2}> 
                <Documents patientid={_userId} type={'Therapist'} ></Documents>
                </TabPanel>
                <TabPanel value={3}>
                <Education  SEduid ={_userId} handleCancel={handleCancel} ></Education>

                {/* <EducationRegistration callingFrom ="Education" Eduid ={_userId}></EducationRegistration> */}
                </TabPanel>
                <TabPanel value={4}>
               <GrowthPlanRegistration callingFrom ="Growth" Growthid ={_userId}></GrowthPlanRegistration>
                </TabPanel>

          </TabContext>
          </Grid>
             
          </Grid>
          
        {/* </Box> */}
      </Grid>
    </React.Fragment>
  );
};
export default SupervisorRegistration;
