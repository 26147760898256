import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Container,
  IconButton,
  LinearProgress,
  InputAdornment,
  Grid,
} from '@mui/material';
import { Height, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from "@mui/icons-material";
import Select from "react-select";
import md5 from "md5";
import axios from "axios";
import Swal from 'sweetalert2';
export default function SupervisonFormSignUp() {
  const [state, setState] = React.useState('');
  const [step, setStep] = React.useState(1); // Step state to toggle between containers
  const [loading, setLoading] = React.useState(false); // Loading state for progress bar
  const [showPassword, setShowPassword] = React.useState(false); // Password visibility toggle
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   if (!validatePasswordFields()) {
  //     return;
  //   }
  //   try {
  //     const postData = {
  //       fullName: `${firstName} ${lastName}`,
  //       dob: dob,
  //       email: email,
  //       contactNo: phone,
  //       address: street,
  //       street: street,
  //       city: city,
  //       userRole: 'Supervisor',
  //       state: selectedState,
  //       zipCode: zip,
  //       password: md5(password),
  //       userName: email, 
  //     };
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/Users/userRegistration`,
  //       postData
  //     );
  //     const userEmail = response.data.email;
  //     if (userEmail) {
  //       localStorage.setItem('SignUpuserEmail', userEmail);
  //     }
  //     setTimeout(() => {
  //       setLoading(false);
  //       const container = document.getElementById('password-container');
  //       container.style.animation = 'shuffleOut 0.5s ease-in-out';
  //       setTimeout(() => {
  //         navigate('/SubscriptionFormPage');
  //       }, 500); // 500ms delay before navigating
  //     }, 1000); // 1 second delay before animation

  //   } catch (error) {
  //     // Handle the error case
  //     setLoading(false);
  //     console.error("Error during registration:", error);
  //     return;
  //   }
  // };
  const HandleCloseModal = () => {
    navigate('/SubscriptionFormPage');
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    if (!validatePasswordFields()) {
      setLoading(false); // Stop loading if validation fails
      return;
    }
  
    try {
      const postData = {
        fullName: `${firstName} ${lastName}`,
        dob: dob,
        email: email,
        contactNo: phone,
        address: street,
        street: street,
        city: city,
        userRole: 'Supervisor',
        state: selectedState,
        zipCode: zip,
        password: md5(password),
        userName: email,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Users/userRegistration`,
        postData
      );
  
      const userEmail = response.email; // Fixed response structure
  
      if (userEmail) {
        localStorage.setItem('SignUpuserEmail', userEmail);
        await Swal.fire({
          icon: 'success',
          title: 'Record Submitted',
          text: 'Record Submitted to Maevesory successfully.',
          confirmButtonText: 'OK',
        });
  
        HandleCloseModal(); // Navigate after the Swal alert is shown
      }
    } catch (error) {
      setLoading(false);
      console.error('Error during registration:', error);
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: error.response?.data?.message || 'This email is already registered.',
        confirmButtonText: 'OK',
      });
      return;
    }
  };
  
  
  const [formErrors, setFormErrors] = useState([]);
  const [FormErrorsPassWord, setFormErrorsPassWord] = useState([]);
  const validateForm = () => {
    const errors = [];

    // Validate fields
    if (!firstName) errors.push('First name is required');
    if (!lastName) errors.push('Last name is required');
    if (!dob) errors.push('Date of birth is required');
    if (!email) errors.push('Email is required');
    if (!/\S+@\S+\.\S+/.test(email)) errors.push('Email must be a valid email address');
    if (!phone) errors.push('Phone number is required');
    if (!/^\d{10}$/.test(phone)) errors.push('Phone number must be 10 digits');
    if (!street) errors.push('Street is required');
    if (!city) errors.push('City is required');
    if (!zip) errors.push('Zip code is required');
    if (!/^\d{5}$/.test(zip)) errors.push('Zip code must be 5 digits');
    setFormErrors(errors);
    return errors.length === 0;
  };
  const validatePasswordFields = () => {
    const errors = [];

    if (!password) {
      errors.push('Password is required');
    } else {
      if (password.length < 8) errors.push('Password must be at least 8 characters');
      if (!/[A-Z]/.test(password)) errors.push('Password must contain at least one uppercase letter');
      if (!/[a-z]/.test(password)) errors.push('Password must contain at least one lowercase letter');
      if (!/\d/.test(password)) errors.push('Password must contain at least one number');
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push('Password must contain at least one special character');
    }
    if (!confirmPassword) {
      errors.push('Confirm password is required');
    } else if (password !== confirmPassword) {
      errors.push('Passwords do not match');
    }

    setFormErrorsPassWord(errors);
    return errors.length === 0;
  };
  const handleNext = () => {
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    const container = document.getElementById('form-container');
    container.style.animation = 'shuffleOut 0.5s ease-in-out';
    setTimeout(() => {
      setStep(2);
      setLoading(false);
    }, 500);
  };
  const handleBack = () => {
    setLoading(true);
    const container = document.getElementById('password-container');
    container.style.animation = 'shuffleOut 0.5s ease-in-out';
    setTimeout(() => {
      setStep(1);
      setLoading(false);
    }, 500);
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   const container = document.getElementById('password-container');
  //   container.style.animation = 'shuffleOut 0.5s ease-in-out';
  //   setTimeout(() => {
  //     setLoading(false);
  //     navigate('/SubscriptionFormPage');
  //   }, 500); // Navigate after animation
  // };
  // Inline styles
  const styles = {
    container: {
      minHeight: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',

    },
    backgroundImageContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      backgroundImage: 'url("/mavesory.png")', // Your background image URL
      backgroundSize: '45%', // Reduces the image size to 30% of its original size
      backgroundPosition: 'left', // Keeps the image aligned to the left
      backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat
      zIndex: -3, // Keeps the image behind the content
    },
    innerContainer: {
      backgroundColor: '#fff',
      borderRadius: '20px',
      padding: '20px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      maxWidth: '540px',
      width: '100%',
      borderTop: '3px solid rgba(0, 0, 0, 0.1)', 
      animation: 'fadeIn 0.5s ease-in-out',
    },
    formTitle: {
      color: '#2C3E2D',
      marginBottom: '10px',
      fontWeight: 'bold',
    },
    textField: {
      // background: 'white',
      borderRadius: '4px',
      marginBottom: '10px',
      '& .MuiOutlinedInput-root': {
        background: 'green', // Green background color for the input
        borderRadius: '4px',
        height: '40px', // Set height of the input
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)', // Optional: Adjust border color
        },
        '&:hover fieldset': {
          borderColor: 'darkgreen', // Optional: Adjust hover border color
        },
        '&.Mui-focused fieldset': {
          borderColor: 'darkgreen', // Optional: Adjust focus border color
        },
      },
      '& .MuiInputBase-input': {
        fontSize: '14px', // Adjust font size
        // padding: '10px', // Add padding for better alignment
      },
    },
    nextButton: {
      background: '#7A9483',
      color: 'white',
      padding: '10px 30px',
      borderRadius: '20px',
      marginTop: '10px',
      '&:hover': {
        background: '#658570',
      },
    },
    ulStyle: {
      listStyleType: 'disc',
      color: '#333',
      fontSize: '14px',
    },
    liStyle: {
      marginBottom: '8px',
      color: '#555',
    },
  };

  // CSS for animations as a <style> tag
  const animations = `
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes shuffleOut {
      from {
        opacity: 1;
        transform: translateX(0);
      }
      to {
        opacity: 0;
        transform: translateX(-100%);
      }
    }
  `;
  const [selectedState, setSelectedState] = useState(null);
  const states = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
  ];
  return (
    <React.Fragment>
      <style>{animations}</style>
      <Container maxWidth={false} style={styles.container}>
      <div style={styles.backgroundImageContainer}></div>
        {loading && <LinearProgress style={{ width: '100%', position: 'absolute', top: 0, color: 'green' }} />}
        {step === 1 && (
          <Box id="form-container" style={styles.innerContainer}>
            <Typography variant="h5" style={styles.formTitle}>
              SUPERVISOR SIGN UP
            </Typography>

            <Grid container spacing={1} style={{
              height: '350px', overflowY: 'auto',
              scrollbarWidth: 'thin',
              scrollbarColor: '#aec3b0 transparent',
              '&::-webkit-scrollbar': {
                width: 8,
                height: 8,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#aec3b0',
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },

              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4,
              },
            }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="FIRST NAME"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={styles.textField}
                  InputProps={{ style: { height: '40px' } }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="LAST NAME"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  style={styles.textField}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  InputProps={{ style: { height: '40px' } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="DATE OF BIRTH"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  style={styles.textField}
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  InputProps={{ style: { height: '40px' } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="EMAIL"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  style={styles.textField}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{ style: { height: '40px' } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="PHONE"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={styles.textField}
                  InputProps={{ style: { height: '40px' } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="STREET NUMBER OR ADDRESS"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  style={styles.textField}
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  InputProps={{ style: { height: '40px' } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="CITY"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  style={styles.textField}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  InputProps={{ style: { height: '40px' } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  options={states}
                  value={states.find(option => option.label === selectedState)} // Set the selected state
                  onChange={(option) => {
                    setSelectedState(option.label); // Update local state
                  }}
                  placeholder="Select a state"
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: '40px', // Make it match the TextField height
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="ZIP CODE"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                  style={styles.textField}
                  InputProps={{ style: { height: '40px' } }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {formErrors.length > 0 && (
                  <div style={{ color: 'red', marginBottom: '10px' }}>
                    <ul>
                      {formErrors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </Grid>
            </Grid>
            <Button
              onClick={handleNext}
              variant="contained"
              fullWidth
              style={styles.nextButton}
            >
              NEXT
            </Button>
          </Box>

        )}

        {step === 2 && (
          <Box id="password-container" style={styles.innerContainer}>
            <IconButton
              onClick={handleBack}
              style={{
                backgroundColor: "#8C7A63",
                color: "white",
                border: "3px solid #fff",
                padding: "6px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.2s, background-color 0.2s",
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold', color: '#333' }}>
              CREATE A PASSWORD:
            </Typography>
            <ul style={{ ...styles.ulStyle, marginTop: '10px', padding: '0 20px', lineHeight: '1.8' }}>
              <li style={styles.liStyle}>At least 8 characters.</li>
              <li style={styles.liStyle}>A mixture of both uppercase and lowercase letters.</li>
              <li style={styles.liStyle}>A mixture of letters and numbers.</li>
              <li style={styles.liStyle}>Inclusion of at least one special character, e.g., ! @ # ? []</li>
              <span style={{ display: 'block', marginTop: '10px', color: '#555' }}>
                <b>Note:</b> Do not use "&lt;" or "&gt;" in your password as both can cause problems in web browsers.
              </span>
            </ul>
            <Box sx={{ position: 'relative', marginBottom: '10px' }}>
              <TextField
                fullWidth
                label="PASSWORD"
                type={showPassword ? 'text' : 'password'}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                style={styles.textField}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onMouseDown={() => setShowPassword(true)}
                        onMouseUp={() => setShowPassword(false)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { height: '40px' },
                }}
              />

            </Box>
            <Box sx={{ position: 'relative', marginBottom: '10px' }}>
              <TextField
                fullWidth
                label="CONFIRM PASSWORD"
                type={showConfirmPassword ? 'text' : 'password'}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                style={styles.textField}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onMouseDown={() => setShowConfirmPassword(true)}
                        onMouseUp={() => setShowConfirmPassword(false)}
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { height: '40px' },
                }}
              />

            </Box>
            <Box>
              {FormErrorsPassWord.length > 0 && (
                <div style={{ color: 'red', marginBottom: '10px' }}>
                  <ul>
                    {FormErrorsPassWord.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </Box>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={styles.nextButton}
              onClick={handleSubmit}
            >
              {loading ? 'Submitting...' : 'NEXT'}
            </Button>
          </Box>
        )}
      </Container>
    </React.Fragment>
  );
}
