import PropTypes from "prop-types";
import React, { useEffect } from "react";
import logolight from "../../assets/images/maevisorylogo.png";
import logodark from "../../assets/images/maevisorylogo.png";
import logoSm from "../../assets/images/maevisorylogo.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

//Import config
import { facebook, google } from "../../config";
import { useState } from "react";
import { Modal, Box, Typography, Button, } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AgendaRegistration from "../AgendaRegistration";
import SupervisorSignUp from "../SupervisorSignUp";
import SuperviseeSignUp from "../SuperviseeSignUp";
import SupervisonFormSignUp from "../SupervisonFormSignUp";
const Login = props => {
  document.title = "Login | Maevisory";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userName: '',
      password: '',
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    }
  });

  const { error } = useSelector(state => ({
    error: state.login.error,
  }));

  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   dispatch(loginUser(values, props.router.navigate));
  // };

  // const signIn = (res, type) => {
  //   if (type === "google" && res) {
  //     const postData = {
  //       name: res.profileObj.name,
  //       email: res.profileObj.email,
  //       token: res.tokenObj.access_token,
  //       idToken: res.tokenId,
  //     };
  //     dispatch(socialLogin(postData, props.router.navigate, type));
  //   } else if (type === "facebook" && res) {
  //     const postData = {
  //       name: res.name,
  //       email: res.email,
  //       token: res.accessToken,
  //       idToken: res.tokenId,
  //     };
  //     dispatch(socialLogin(postData, props.router.navigate, type));
  //   }
  // };

  //handleGoogleLoginResponse
  // const googleResponse = response => {
  //   signIn(response, "google");
  // };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  // const facebookResponse = response => {
  //   signIn(response, "facebook");
  // };

  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });
  const navigate = useNavigate();

  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [OpenSignInPageSupervisor, setOpenSignInPageSupervisor] = useState(false);
  const [OpenSignInPageSupervisee, setOpenSignInPageSupervisee] = useState(false);
  const handleCloseSupervisorSign = () => setOpenSignInPageSupervisor(false);
  const handleCloseSuperviseeSign = () => setOpenSignInPageSupervisee(false);

  const handleSignUpOpen = () => setSignUpModalOpen(true);
  const handleSignUpClose = () => setSignUpModalOpen(false);
  const handleNext = () => {
    if (selectedRole === 'Supervisor') {
      // setOpenSignInPageSupervisor(true)
      navigate("/SupervisorSignUp");
    } else if (selectedRole === 'Supervisee') {
      setOpenSignInPageSupervisee(true)
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 400, // Fixed height for the modal
    backgroundColor: "#aec3b0",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    textAlign: "center",
    overflowY: "auto", // Enable vertical scrolling
  };
  const modalStyleSignUp = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 500, // Fixed height for the modal
    backgroundColor: "#aec3b0",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    textAlign: "center",
    overflowY: "auto",
    '&::-webkit-scrollbar': {
      width: 8,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8C7A63',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4,
    },

    '&*': {
      scrollbarWidth: 'thin',
    },
  };

  const buttonStyle = (isActive) => ({
    border: isActive ? '5px solid #8C7A63' : '5px solid #8C7A63',
    borderRadius: '50px',
    padding: '10px 20px',
    color: isActive ? 'white' : '#8C7A63',
    backgroundColor: isActive ? '#8C7A63' : 'transparent',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '15px',
    cursor: 'pointer',
  });

  const arrowStyle = {
    fontSize: '24px', // Increased icon size
    fontWeight: 'bold',
    color: 'inherit',
  };

  const nextButtonStyle = {
    marginTop: '10px',
    border: '3px solid #fff',
    borderRadius: '50px',
    padding: '10px 30px',
    backgroundColor: '#8C7A63',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };

  return (
    <React.Fragment>

      {/* <div className="bg-overlay"></div> */}
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={8} xl={6}>
              <Card style={{ boxShadow: 'none', border: 'none' }}>
                <CardBody style={{ padding: '0', border: 'none' }}>
                  <div style={{ flex: 1 }}>
                    <img
                      src="/mavesory.png"
                      alt="Mavesory"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block', // Prevents default inline image spacing
                        border: 'none',
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} md={4} xl={4}>

              <Card style={{ borderRadius: '15px', backgroundColor: '#aec3b0' }}>
                <CardBody className="p-4" >
                  <div style={{ borderRadius: '25px', backgroundColor: '#eeeded', padding: '30px' }}>
                    {/* <div className="text-center">
                    <Link to="/">
                      {/* <img
                        src={logodark}
                        alt=""
                        height="24"
                        className="auth-logo logo-dark mx-auto"
                      />
                      <img
                        src={logolight}
                        alt=""
                        height="24"
                        className="auth-logo logo-light mx-auto"
                      /> 
                       {/* <span className="logo-lg">
                  <img src={logoSm} alt="logo-sm-light" height="120" style={{marginTop:'-21px'}}/>
                </span>
                    </Link>
                  </div> */}
                    <h4 style={{ color: '#8C7A63', fontWeight: 'bold', textAlign: 'left', fontSize: '28px' }}>
                      LOGIN
                    </h4>
                    {/* <h4 className="font-size-10 text-muted mt-2 text-center">
                    Sign in to continue
                  </h4> */}
                    {/* <p className="mb-5 text-center">
                    Sign in to continue to Upzet.
                  </p> */}
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger"><div>{error}</div></Alert> : null}
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label style={{ color: '#8C7A63' }}>User Name</Label>
                            <Input
                              // style={{borderRadius:'20px'}}
                              name="userName"
                              // className="form-control"
                              style={{
                                // borderRadius: '20px',
                                backgroundColor: '#aec3b0',
                                color: 'black',
                                height: '40px',
                              }}
                              placeholder="Enter user Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.userName || ""}
                              invalid={
                                validation.touched.userName && validation.errors.userName ? true : false
                              }
                            />
                            {validation.touched.userName && validation.errors.userName ? (
                              <FormFeedback type="invalid"><div>{validation.errors.userName}</div></FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label style={{ color: '#8C7A63' }}>Password</Label>
                            <Input
                              style={{
                                // borderRadius: '20px',
                                backgroundColor: '#aec3b0',
                                color: 'black',
                                height: '40px',
                              }}
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid"><div> {validation.errors.password} </div></FormFeedback>
                            ) : null}
                          </div>

                          {/* <Row>
                          <Col>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-label form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>
                          </Col>
                          <Col className="col-7">
                            <div className="text-md-end mt-3 mt-md-0">
                              <Link
                                to="/auth-recoverpw"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock"></i> Forgot your
                                password?
                              </Link>
                            </div>
                          </Col>
                        </Row> */}
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4px' }}>
                            <div style={{ fontWeight: 'bold', fontSize: '14px' }}>remember me</div>
                            <div style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              textDecoration: 'underline',
                              color: '#8C7A63',
                              fontStyle: 'italic',
                              cursor: 'pointer'
                            }}>forget password</div>
                          </div>
                          <div className="d-grid mt-2">
                            <button
                              style={{ borderRadius: '20px', backgroundColor: '#aec3b0', color: 'white', height: '40px', borderColor: "#aec3b0", fontSize: '14px', fontWeight: 'bold' }}
                              // className="btn btn-primary waves-effect waves-light"

                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                          <div className="mt-4 text-center">


                            {/* <Link
                                to="/auth-recoverpw"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock"></i> Forgot your
                                password?
                              </Link> */}
                            {/* <h5 className="font-size-14 mb-3">Sign in with</h5> */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div style={{ fontWeight: 'bold', fontSize: '14px' }}>Don't have an account? </div>
                              <div
                                onClick={handleSignUpOpen}
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '14px',
                                  textDecoration: 'underline',
                                  color: '#8C7A63',
                                  fontStyle: 'italic',
                                  cursor: 'pointer'
                                }}
                              >
                                Sign Up Now
                              </div>
                            </div>

                            {/* <ul className="list-inline">
                        <li className="list-inline-item">
                          <FacebookLogin
                            // appId={facebook.APP_ID}
                            autoLoad={false}
                            // callback={facebookResponse}
                            render={renderProps => (
                              <Link
                                to="#"
                                className="social-list-item bg-primary text-white border-primary"
                                onClick={renderProps.onClick}
                              >
                                <i className="mdi mdi-facebook" />
                              </Link>
                            )}
                          />
                        </li>

                        <li className="list-inline-item">
                          <GoogleLogin
                            // clientId={google.CLIENT_ID}
                            render={renderProps => (
                              <Link
                                to="#"
                                className="social-list-item bg-danger text-white border-danger"
                                onClick={renderProps.onClick}
                              >
                                <i className="mdi mdi-google" />
                              </Link>
                            )}
                            // onSuccess={googleResponse}
                            onFailure={() => { }}
                          />
                        </li>
                      </ul> */}
                          </div>

                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
              <p className="text-white-50">
                Don't have an account ?{" "}
                <Link to="/register" className="fw-medium text-primary">
                  {" "}
                  Register{" "}
                </Link>{" "}
              </p>
              <p className="text-white-50">
                © {new Date().getFullYear()} Upzet. Crafted with{" "}
                <i className="mdi mdi-heart text-danger"></i> by Themesdesign
              </p>
            </div> */}
            </Col>
          </Row>
        </Container>
        <Modal open={isSignUpModalOpen} onClose={handleSignUpClose}>
          <Box sx={modalStyle}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
              Choose Your Role
            </Typography>
            <Box
              sx={{
                backgroundColor: "#cfcccc",
                borderRadius: "10px",
                padding: "20px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <div
                style={buttonStyle(selectedRole === 'Supervisor')}
                onClick={() => setSelectedRole('Supervisor')}
              >
                <span>Supervisor Sign Up</span>
                <span style={arrowStyle}><ArrowForwardIosIcon style={{ fontWeight: 'bolder' }} /></span>
              </div>

              <div
                style={buttonStyle(selectedRole === 'Supervisee')}
                onClick={() => setSelectedRole('Supervisee')}
              >
                <span>Supervisee Sign Up</span>
                <span style={arrowStyle}><ArrowForwardIosIcon style={{ fontWeight: 'bolder' }} /></span>
              </div>
            </Box>
            <Button style={nextButtonStyle} onClick={handleNext}>
              NEXT <KeyboardDoubleArrowRightIcon style={{
                borderRadius: '50%',
                border: '2px solid white',
                marginLeft: '3px',
                color: '#b3946d',
                backgroundColor: '#fff',
                fontWeight: 'bold'
              }} />
            </Button>
          </Box>
        </Modal>
        <Modal open={OpenSignInPageSupervisor} onClose={handleCloseSupervisorSign} >
          <Box sx={modalStyleSignUp}>

            {/* <SupervisorSignUp setSignUpModalOpen ={setSignUpModalOpen} setOpenSignInPageSupervisor ={setOpenSignInPageSupervisor}></SupervisorSignUp> */}
            <SupervisonFormSignUp></SupervisonFormSignUp>
          </Box>
        </Modal>
        <Modal open={OpenSignInPageSupervisee} onClose={handleCloseSuperviseeSign} >

          <Box sx={modalStyleSignUp}>
            <SuperviseeSignUp setSignUpModalOpen={setSignUpModalOpen} setOpenSignInPageSupervisee={setOpenSignInPageSupervisee}></SuperviseeSignUp>
          </Box>
        </Modal>

      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
