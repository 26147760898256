import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function SubscriptionFormPage() {
  const navigate = useNavigate();
  const [packages, setPackages] = useState(null); // To store fetched packages
  const [selectedPackage, setSelectedPackage] = useState(null); // To store selected package
  const [loading, setLoading] = useState(true); // To manage loading state

  useEffect(() => {
    // Fetch packages from API
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/GetAllPackage`)
      .then((response) => {
        setPackages(response); // Store the fetched data
        setLoading(false); // Set loading to false when the API call completes
      })
      .catch((error) => {
        console.error('Error fetching packages:', error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, []);

  const handleCardClick = (duration, stripeProductId) => {
    setSelectedPackage({ duration, stripeProductId });
  };
  const [step, setStep] = React.useState(1); // Step state to toggle between containers

  const handleNext = (event) => {
    event.preventDefault();
    // Simulate API call before navigation
    // if (selectedPackage) {
    //   setTimeout(() => {
    //     navigate('/SubscriptionConfirmationPage');
    //   }, 500); // Delay for animation effect
    // }
    setLoading(true);
    const container = document.getElementById('Subscription-container');
    container.style.animation = 'shuffleOut 0.5s ease-in-out';
    setTimeout(() => {
      setStep(2);
      setLoading(false);
    }, 500);
  };
  const handleNextPage = (event) => {
    event.preventDefault();
    setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate('/SubscriptionConfirmationPage');
      }, 500); // Delay for animation effect
    }
  const styles = {
    container: {
      minHeight: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
    },
    backgroundImageContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      backgroundImage: 'url("/mavesory.png")',
      backgroundSize: '45%',
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      zIndex: -3,
    },
    innerContainer: {
      backgroundColor: '#fff',
      borderRadius: '20px',
      padding: '20px',
      maxWidth: '540px',
      width: '100%',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      borderTop: '3px solid rgba(0, 0, 0, 0.1)',
      animation: 'fadeIn 0.5s ease-in-out',
    },
    formTitle: {
      color: '#2C3E2D',
      marginBottom: '20px',
      fontWeight: 'bold',
    },
    textField: {
      background: 'white',
      borderRadius: '4px',
      marginBottom: '15px',
    },
    nextButton: {
      background: '#7A9483',
      color: 'white',
      padding: '10px 30px',
      borderRadius: '20px',
      marginTop: '20px',
      '&:hover': {
        background: '#658570',
      },
    },
    card: {
      marginBottom: '10px',
      cursor: 'pointer',
      backgroundColor: '#a1c2a2',
      borderRadius: '10px',
      alignItems: 'center',
      transition: 'all 0.3s ease',
    },
    selectedCard: {
      border: '5px solid #b3946d',
    },
    button: {
      width: "200px",
      backgroundColor: "#a1c2a2",
      color: "white",
      padding: "10px 0",
      borderRadius: "5px",
      margin: '5px'
    },
  };

  // CSS for animations
  const animations = `
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;
  const emailofUser = localStorage.getItem("SignUpuserEmail")
  const handleCreateStripe = () => {
    if (emailofUser) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/Users/createStripeAccount?email=${emailofUser}`)
        .then(response => {

        })
        .catch(error => {
        });
    }
  };
  const handleLinkStripe = () => {
    if (emailofUser) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/Users/linkStripeAccount?email=${emailofUser}`)
        .then(response => {

        })
        .catch(error => {
        });
    }
  };
  return (
    <React.Fragment>
      {/* Include animations in the same file */}
      <style>{animations}</style>
      <Container maxWidth={false} style={styles.container}>
        <div style={styles.backgroundImageContainer}></div>
        {loading && <LinearProgress style={{ width: '100%', position: 'absolute', top: 0, color: 'green' }} />}
        {step === 1 && (
          <Box id="Subscription-container" style={styles.innerContainer}>
            <Typography variant="h5" style={styles.formTitle}>
              Subscriptions
            </Typography>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="200px"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {/* Display packages if loaded */}
                {packages && packages.length > 0 ? (
                  packages.map((pkg) => (
                    <Card
                      key={pkg.id}
                      onClick={() => handleCardClick(pkg.duration, pkg.stripeProductId)}
                      sx={{
                        ...styles.card,
                        ...(selectedPackage?.duration === pkg.duration && styles.selectedCard),
                      }}
                    >
                      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography style={{ fontWeight: 'bold' }}>{pkg.packageName}</Typography>
                        <Typography>{`$${pkg.price} + TAX`}</Typography>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography>No packages available</Typography>
                )}
                <Button
                  variant="contained"
                  fullWidth
                  style={styles.nextButton}
                  onClick={handleNext}
                // disabled={!selectedPackage} // Disable if no package is selected
                >
                  Subscribe
                </Button>
              </>
            )}

          </Box>
        )}
        {step === 2 && (
          <Box id="Stripe-container" style={styles.innerContainer}>
            <Typography variant="h5" style={styles.formTitle}>
              Stripe
            </Typography>
            < TextField
              label="Account ID"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{ style: { height: '40px' } }}
            />
            <Button onClick={handleCreateStripe} variant='contained' style={styles.button} >
              Create Stripe
            </Button>
            <Button onClick={handleLinkStripe} variant='contained' style={styles.button}>
              Link Stripe
            </Button>
            <Button
                  variant="contained"
                  fullWidth
                  style={styles.nextButton}
                  onClick={handleNextPage}
                // disabled={!selectedPackage} // Disable if no package is selected
                >
                  NEXT
                </Button>
          </Box>
        )}
      </Container>
    </React.Fragment>
  );
}
