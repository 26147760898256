import { useState, useEffect, useRef } from "react";
import React from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import 'react-confirm-alert/src/react-confirm-alert.css'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  InputLabel
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import Container from '@mui/material/Container'
import { DataGrid } from "@mui/x-data-grid";
import { useAlert } from "react-alert";
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HandshakeIcon from '@mui/icons-material/Handshake';
const SignupRequests = props => {
  document.title = "SignupRequests | Maevisory";
  const fields = [
    "id",
    "practiceId",
    "firstName",
    "lastName",
    "createdBy",
    "updatedBy",
    "phone",
    "email",
    "address",
    "city",
    "state",
    "zip",
    "type",
    "password",
    "profilePictureURL",
    "gender",
    "preferredGender",
    "ethnicity",
    "race",
    "licenseType",
    "licenseState",
    "licenseNumber",
    "initialLicensureDate",
    "licenseExpirationDate",
    "supervisortrainingtitle",
    "dateofCompletedTraining",
    "uploadTrainingCertificateURL",
    "liabilityInsuranceCarrier",
    "policyNumber",
    "insurancePolicyStartDate",
    "insurancePolicyEndDate",
    "uploadCopyofInsuranceURL",
    "pdf",
    "inActive",
    "userAggreementCheck",
    "createdDate",
    "updatedDate",
    "black",
    "white",
    "americanIndian",
    "nativeHawaiian",
    "asian",
    "middleEastern",
    "multiracial",
    "other",
    "lpcSupervisor",
    "lcswSupervisor",
    "lmftSupervisor",
    "bcba",
    "licensedSpecialist",
    "psychologist",
    "male",
    "female",
    "nonBinary",
    "intersex",
    "transWoman",
    "transMan",
    "hispanic",
    "notHispanic",
    "lpcAssociate",
    "lmftAssociate",
    "lmswormsw",
    "lcdci",
    "bcbaTrainee",
    "psychologicalAssociate",
    "pendingLicens",
    "directhoursrequired",
    "directhourshave",
    "indirecthoursrequired",
    "indirecthourshave",
    "profilePictureBase64",
    "uploadTrainingCertificateBase64",
    "uploadCopyofInsuranceBase64",
    "fullname",
  ];
  const defValues = {
    id: 0,
    practiceId: 0,
    firstName: '',
    lastName: '',
    fullname: '',
    createdBy: '',
    updatedBy: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    type: '',
    password: '',
    profilePictureURL: '',
    gender: '',
    preferredGender: '',
    ethnicity: '',
    race: '',
    licenseType: '',
    licenseState: '',
    licenseNumber: '',
    initialLicensureDate: '',
    licenseExpirationDate: '',
    supervisortrainingtitle: '',
    dateofCompletedTraining: '',
    uploadTrainingCertificateURL: '',
    liabilityInsuranceCarrier: '',
    policyNumber: '',
    insurancePolicyStartDate: '',
    insurancePolicyEndDate: '',
    uploadCopyofInsuranceURL: '',
    pdf: '',
    inActive: false,
    userAggreementCheck: '',
    createdDate: '',
    updatedDate: '',
    black: '',
    white: '',
    americanIndian: '',
    nativeHawaiian: '',
    asian: '',
    middleEastern: '',
    multiracial: '',
    other: '',
    lpcSupervisor: '',
    lcswSupervisor: '',
    lmftSupervisor: '',
    bcba: '',
    licensedSpecialist: '',
    psychologist: '',
    male: '',
    female: '',
    nonBinary: '',
    intersex: '',
    transWoman: '',
    transMan: '',
    hispanic: '',
    notHispanic: '',
    lpcAssociate: '',
    lmftAssociate: '',
    lmswormsw: '',
    lcdci: '',
    bcbaTrainee: '',
    psychologicalAssociate: '',
    pendingLicens: '',
    directhoursrequired: '',
    directhourshave: '',
    indirecthoursrequired: '',
    indirecthourshave: '',
    profilePictureBase64: '',
    uploadTrainingCertificateBase64: '',
    uploadCopyofInsuranceBase64: "",
  };
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  // const validationSchema = Yup.object().shape({
  //   // therapistId: Yup.string().required('Therapist is required'),  
  //   conductRequiringRemediation: Yup.string().required('Conduct Requiring Remediation is required'),
  //   ethicalRules: Yup.string().required('Ethical Rules are required')
  // });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [Data, setData] = useState(null);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.01,
    },
    {
      field: 'fullname',
      headerClassName: 'super-app-theme--header',
      headerName: 'Name',
      cellClassName: 'gridCell',
      flex: 0.12,
      renderCell: (cellValues) => (
        <Button
          style={{ display: 'flex', justifyContent: 'flex-start' }}
          size="small"
          variant="text"
          color="info"
          onClick={() => {
            setOpenModal(true);
            setRowID(cellValues.row.id);
            let rowid = cellValues.row.id;
            axios
              .get(`${process.env.REACT_APP_API_URL}/Users/getSignUpById?id=${rowid}`, { headers })
              .then((response) => {
                fields.forEach(field => {
                  setValue(field, response[field]);
                })
                setData(response)
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
              });
          }}
        >{`${cellValues.row.fullname}`}</Button>
      ),
    },
    {
      field: 'type',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Type',
      flex: 0.1
    },
    {
      field: 'email',
      headerClassName: 'super-app-theme--header',
      headerName: 'Email',
      flex: 0.1
    },

    {
      field: 'state',
      headerClassName: 'super-app-theme--header',
      headerName: 'State',
      flex: 0.1
    },
    {
      field: 'gender',
      headerClassName: 'super-app-theme--header',
      headerName: 'Gender',
      flex: 0.1
    },
    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.05,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                      inActive: true,
                      id: cell.row.id
                    }
                    axios
                      .post(`${process.env.REACT_APP_API_URL}/Users/SignUp`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(true)
          
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
  ]
  const [refresh, setRefresh] = useState(false)
  const [rows, setRows] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [OpenModal, setOpenModal] = React.useState(false);
  const [rowId, setRowID] = React.useState(0);
  const HandleOpen = () => {
    setOpenModal(true)
  };
  const HandleCloseModal = () => {
    setOpenModal(false)
    fields.forEach((field) => setValue(field, ''));
    reset();
    setRowID(0);
  };
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }
  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/GetAllSignUps`, {
        headers
      })
      .then(response => {
        setRows(response)
        setLoading(false)
      })
      .catch(error => { });
  }, [refresh]);

  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    // padding: '14px',
    transform: 'translate(-50%, -50%)',
    height: 470,
    width: '50%',
    bgcolor: 'white',
    border: '2px solid black',
    boxShadow: 24,
    borderRadius: '8px',
    // overflowY: 'auto'
  }
  const [modalOpenImage, setmodalOpenImage] = useState(false);
  const handleCloseImage = () => {
    setmodalOpenImage(false);
  };
  return (
    <div className="page-content">
      <Container >
        
      <Modal open={modalOpenImage} onClose={handleCloseImage}>
          <Box
            sx={{
              position: 'absolute',
              top: '52%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 912,
              bgcolor: 'background.paper',
              borderColor: '#326C1D',
              border: 5,
              padding: '5px',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Grid
              container xs={12}
              style={{
                background: '#377562',
                position: 'sticky',
                marginBottom: '14px',
                padding: '8px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(54, 118, 196, 0.5)',
                display: 'flex'
              }}
            >
              <Grid item xs={11}>
                <Typography style={{
                  fontSize: '17px',
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  color: '#fff'
                }}>User Agreement</Typography>
              </Grid>
              <Grid item xs={1}>
                <CancelOutlinedIcon
                  onClick={handleCloseImage}
                  fontSize="medium"
                  color="red"
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                    float: 'right',
                  }}
                ></CancelOutlinedIcon>
              </Grid>
            </Grid>
            <div>
              <Grid item xs={8}>
              <iframe
                      height="400px"
                      width="850px"
                      style={{ border: '4px solid #000' }}
                      src={watch("pdf") }
                    />
              </Grid>
              <Grid item xs={1}></Grid>
            </div>
            <Grid
              item
              xs={12}
              style={{
                fontWeight: 500,
                marginLeft: '30px',
                marginBottom: '25px',
                marginTop: '10px',
              }}
            >
              <Button
                className="Button"
                variant="gradient"
                onClick={handleCloseImage}
                style={{
                  color: 'white',
                  fontSize: '13px',
                  height: '27px',
                  marginBottom: '3px',
                  float: 'right',
                }}
              >
                Close
              </Button>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={OpenModal}
          onClose={HandleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Headroom >
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '3rem',
                      padding: '8px'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          // textAlign: 'left',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1.2rem'

                        }}
                      >
                        Sign Up Request Details

                      </h1>
                    </DialogTitle>
                    <IconButton
                      onClick={HandleCloseModal}
                      style={{
                        color: 'red',
                        backgroundColor: 'rgba(255, 77, 77, 0.1)',
                        borderRadius: '50%',
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <CancelOutlinedIcon fontSize="medium" />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Headroom>
            <Grid container spacing={1} style={{
              padding: '10px',
              maxHeight: 420,
              overflowY: 'auto',
              color: '#fff', 
            }}>
              <>
              {/* <Grid item xs={6} sm={6} xl={6}>
                <Button variant="contained"
                 className="Button"
                 startIcon={<AccountBoxIcon/>} 
                 onClick={() => setmodalOpenImage(true)}
                 style={{
                  color:'#fff'
                 }}>View Profile Photo</Button>
                </Grid> */}
                <Grid item xs={12} sm={12} xl={12}>
                <Button
                 variant="contained"
                 className="Button"
                 onClick={() => setmodalOpenImage(true)}
                 startIcon={<HandshakeIcon/>} 
                 fullWidth
                 style={{
                  color:'#fff'
                 }}
                >View User Agreement</Button>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Full Name</InputLabel>
                  <Controller
                    name="fullname"
                    {...register("fullname")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Name"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('fullname', e.target.value);
                        }}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                      />}
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Phone</InputLabel>
                  <Controller
                    name="phone"
                    {...register("phone")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Phone"

                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('phone', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Email</InputLabel>
                  <Controller
                    name="email"
                    {...register("email")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Email"

                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('email', e.target.value);
                        }}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Address</InputLabel>
                  <Controller
                    name="address"
                    {...register("address")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Address"

                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('address', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>City</InputLabel>
                  <Controller
                    name="city"
                    {...register("city")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="City"

                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('city', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Zip</InputLabel>
                  <Controller
                    name="zip"
                    {...register("zip")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Zip"

                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('zip', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>State</InputLabel>
                  <Controller
                    name="state"
                    {...register("state")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="state"

                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('state', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Licence State</InputLabel>
                  <Controller
                    name="licenseState"
                    {...register("licenseState")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('licenseState', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Gender</InputLabel>
                  <Controller
                    name="gender"
                    {...register("gender")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('gender', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Preferred Gender</InputLabel>
                  <Controller
                    name="preferredGender"
                    {...register("preferredGender")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('preferredGender', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Ethnicity</InputLabel>
                  <Controller
                    name="ethnicity"
                    {...register("ethnicity")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('ethnicity', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Liability Insurance Carrier</InputLabel>
                  <Controller
                    name="liabilityInsuranceCarrier"
                    {...register("liabilityInsuranceCarrier")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('liabilityInsuranceCarrier', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Insurance Policy Start Date</InputLabel>
                  <Controller
                    name="insurancePolicyStartDate"
                    {...register("insurancePolicyStartDate")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('insurancePolicyStartDate', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Insurance Policy End Date</InputLabel>
                  <Controller
                    name="insurancePolicyEndDate"
                    {...register("insurancePolicyEndDate")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('insurancePolicyEndDate', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Policy Number</InputLabel>
                  <Controller
                    name="policyNumber"
                    {...register("policyNumber")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('policyNumber', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>License Number</InputLabel>
                  <Controller
                    name="licenseNumber"
                    {...register("licenseNumber")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('licenseNumber', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>Initial Licensure Date</InputLabel>
                  <Controller
                    name="initialLicensureDate"
                    {...register("initialLicensureDate")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('initialLicensureDate', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <InputLabel>License Expiration Date</InputLabel>
                  <Controller
                    name="licenseExpirationDate"
                    {...register("licenseExpirationDate")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          setValue('licenseExpirationDate', e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
              </>
            </Grid>
          </Box>
        </Modal>
        <Grid item xs={12} sm={12} xl={12}>
          <Box mb={0} style={{ marginTop: 0 }}>
            <Typography
          variant="h4"
          component="h2"
          sx={{
            marginBottom: 2,
            fontWeight: 'bold',
          }}
        >
          Sign Up Requests
        </Typography>

            {isLoading ? (
              <div style={{ margin: '5rem', textAlign: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <div mb={0} style={{ height: 400, width: '100%' }}>
                {rows === null || rows.length === 0 ? ("No Record Found") : (
                  <DataGrid
                    rows={rows}
                    classes={gridClasses}
                    rowHeight={20}
                    columns={columns}
                    getRowSpacing={getRowSpacing}
                    //checkboxSelection
                    fullWidth
                    sx={gridRowStyle}
                    pagination
                    autoPageSize
                  />
                )}
              </div>
            )}
          </Box>
        </Grid>

      </Container>
    </div>

  );
};
export default SignupRequests;
